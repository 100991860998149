import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const BarChartComponent = ({ data, title, aspect ,barType="horizontal" }) => {
  // let data = [
  //   {
  //     "count": 105,
  //     "month": 1,
  //     "year": 2023
  //   },
  //   {
  //     "count": 105,
  //     "month": 2,
  //     "year": 2023
  //   },
  //   {
  //     "count": 105,
  //     "month": 3,
  //     "year": 2023
  //   },
  //   {
  //     "count": 58,
  //     "month": 8,
  //     "year": 2023
  //   },
  //   {
  //     "count": 58,
  //     "month": 11,
  //     "year": 2023
  //   },
  //   {
  //     "count": 164,
  //     "month": 9,
  //     "year": 2023
  //   },
  //   {
  //     "count": 3,
  //     "month": 7,
  //     "year": 2023
  //   },
  //   {
  //     "count": 105,
  //     "month": 10,
  //     "year": 2023
  //   },
  
  // ]
  const truncateLabel = (label, maxLength = 200) => {
    if (label.length > maxLength) {
      return `${label.substring(0, maxLength)}...`;
    }
    return label;
  };
  return (
    <div className="bar-chart" style={{ backgroundColor: "#fff", boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)", borderRadius: "10px" }}>
      <div className="title" style={{ padding: "16px 25px", fontWeight: "bold", fontSize: "14px", color: "rgb(160, 160, 160)" }}>{title}</div>
       
      <ResponsiveContainer aspect={aspect}>
        <BarChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 20 }}
          layout={barType}
        >
          <CartesianGrid strokeDasharray="3 3" />
          {barType === "horizontal" ? <><XAxis dataKey="label" stroke="gray" tickFormatter={(label)=> truncateLabel(label,10)} />
          <YAxis type="number" stroke="gray" /></>: <><XAxis type="number" stroke="gray" /> {/* XAxis for numerical values */}
          <YAxis dataKey="label" type="category" stroke="gray" width={200} tickFormatter={(label)=> truncateLabel(label,20)} /> {/* YAxis for labels */}</>} 
          <Tooltip 
            formatter={(value) => value.toFixed(0)} // To show the exact count without rounding
          />
          <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComponent;
