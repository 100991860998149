import {
  Box,
  Button,
  Container,
  Grid,
  Select,
  MenuItem,
  Typography,
  Switch,
  Breadcrumbs,
  Link as MuiLink,
  CircularProgress,
  Input,
  TextareaAutosize,
  Skeleton,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  CreateWhatsAppCampaignValidationSchema,
  EmailCampaignFirstFormValidationSchema,
  EmailCampaignSecondFormValidationSchema,
  EmailCampaignThirdFormValidationSchema,
} from "../../utils/Validations";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { format, addMinutes, set } from "date-fns";

import {
  patchRequestWithToken,
  postRequest,
  getRequestWithToken,
  getRequest,
  deleteRequest,
} from "../../utils/ApiRequests";
import { useContext, useEffect, useRef, useState } from "react";

import { useLocation, Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import FocusError from "../../components/FocusError/FocusError";
import BackBtn from "../../components/backButton/BackBtn";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";
import "./marketing.scss";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ImageIcon from "@material-ui/icons/Image";
import VideoIcon from "@material-ui/icons/VideoLibrary";
import DoneIcon from "@mui/icons-material/Done";
import DocumentIcon from "@material-ui/icons/Description";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import DescriptionIcon from "@material-ui/icons/Description";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Edit, MaximizeTwoTone } from "@material-ui/icons";
import { formatTimeTo24Hour, convertToUTC } from "../../utils/functions";
import CreatableSelect from "react-select/creatable";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import parse from "html-react-parser";
import LinearProgress from "@mui/material/LinearProgress";
import * as yup from "yup";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import { read, utils } from "xlsx";

const CreateEmail = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [dataById, setDataById] = useState([]);
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(true);

  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);
  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);

  const [fileType, setFileType] = useState("");
  const [uploadErrors, setUploadErrors] = useState({});
  const [fileUpload, setFileUpload] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState(null);
  const [variablesList, setVariablesList] = useState(null);
  const [newTagValue, setNewTagValue] = useState(null);
  const [linearLoader, setLinearLoader] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const [conditionsValue, setConditionsValue] = useState(null);
  const [dataSourceValue, setDataSourceValue] = useState(null);
  const [csvFile, setCsvFile] = useState(null);
  const [campaignNameIs, setCampaignNameIs] = useState(null);
  const [keysValues, setKeysValues] = useState(null);
  const [csvError, setCsvError] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [emptyCsvFile, setEmptyCsvFile] = useState(null);
  const [CSVFileChecking, setCSVFileChecking] = useState(false);
  // const [inputKey, setInputKey] = useState(Date.now());

  const handleFileTypeChange = (event) => {
    const newFileType = event.target.value;
    setFileType(newFileType);
    setFileUpload(null);

    if (fileUpload) {
      const isValidFileType =
        (newFileType === "image" && fileUpload.type.startsWith("image/")) ||
        (newFileType === "video" && fileUpload.type.startsWith("video/")) ||
        (newFileType === "document" &&
          [
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ].includes(fileUpload.type));

      if (!isValidFileType) {
        setUploadErrors((prevErrors) => ({
          ...prevErrors,
          fileUpload: `Please upload a ${newFileType} file.`,
        }));
      } else {
        setUploadErrors((prevErrors) => ({
          ...prevErrors,
          fileUpload: null,
        }));
      }
    }
  };

  const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB
  const MAX_VIDEO_DOC_SIZE = 10 * 1024 * 1024; // 10MB

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const isValidFileType =
      (fileType === "image" && file.type.startsWith("image/")) ||
      (fileType === "video" && file.type.startsWith("video/")) ||
      (fileType === "document" &&
        [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ].includes(file.type));

    const maxSize = fileType === "image" ? MAX_IMAGE_SIZE : MAX_VIDEO_DOC_SIZE;
    const isValidFileSize = file.size <= maxSize;

    if (!isValidFileType) {
      setUploadErrors((prevErrors) => ({
        ...prevErrors,
        fileUpload: `Please upload a ${fileType} file.`,
      }));
      setFileUpload(null);
    } else if (!isValidFileSize) {
      const maxSizeMB = maxSize / (1024 * 1024);
      setUploadErrors((prevErrors) => ({
        ...prevErrors,
        fileUpload: `File size should be less than ${maxSizeMB}MB.`,
      }));
      setFileUpload(null);
    } else {
      setUploadErrors((prevErrors) => ({
        ...prevErrors,
        fileUpload: null,
      }));
      setFileUpload(file);
      console.log("fileUpload:", fileUpload);
    }
  };

  const getPlaceholderText = () => {
    switch (fileType) {
      case "image":
        return "Choose an image file";
      case "video":
        return "Choose a video file";
      case "document":
        return "Choose a document file";
      default:
        return "Choose a file";
    }
  };

  const getNoteText = () => {
    switch (fileType) {
      case "image":
        return "Image size should be less than 5mb";
      case "video":
        return "Video size should be less than 10mb";
      case "document":
        return "PDF size should be less than 10mb";
      default:
        return "File size should be less than 10mb";
    }
  };

  const [dateTimePickers, setDateTimePickers] = useState([{}]);

  const addDateTimePicker = () => {
    setDateTimePickers([...dateTimePickers, {}]);
  };

  const removeDateTimePicker = (index) => {
    setDateTimePickers(dateTimePickers.filter((_, i) => i !== index));
  };

  const [initialValues, setInitialValues] = useState({
    campaignName: "",
    campaignType: "",
    templateId: "",
    csvFile: "",
    bodyTextContent: "",
    templateLanguage: "",
    fileType: "",
    fileUpload: "",
    sendLater: false,
    // date: null,
    // time: null,
    scheduledTime: null,
    tag: "",
  });
  const [editForm, setEditForm] = useState(false);
  const { handleSnackBar } = useContext(GlobalContext);

  const scrollTargetRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm, setErrors }) => {
    if (values.sendLater && values.scheduleTime === "") {
      setErrors({ scheduleTime: "Please select date and time" });
      return;
    }
    console.log("values.scheduleTime : ", values.scheduleTime);
    let scheduledTimeValue = values.scheduleTime
      ? values.scheduleTime
      : new Date().toISOString();

    // console.log('values.timeafter : ', scheduledTimeValue)

    setFormSubmitted(true);
    let formData = new FormData();

    formData.append("modeOfSend", "whatsapp");
    formData.append("campaignName", values.campaignName);
    formData.append("campaignType", values.campaignType);
    formData.append("csvFile", values.csvFile);
    formData.append(
      "params",
      JSON.stringify({
        whatsappParams: {
          templateId: values.templateId,
          templateContent: null,
          templateLanguage: values.templateLanguage,
        },
      })
    );
    formData.append("bodyTextContent", values.bodyTextContent);
    formData.append("image", fileUpload);
    formData.append("scheduledTime", scheduledTimeValue);
    formData.append("tag", value?.label);
    setShowLoader(true);

    try {
      let res = await postRequest("admin/campaign-schedule", formData);

      if (res.data.status === "Success") {
        setShowLoader(false);
        setFormSubmitted(false);
        if (!editForm) {
          resetForm();
          fileRef.current.value = "";
          if (scrollTargetRef.current) {
            scrollTargetRef.current.scrollIntoView({ behavior: "smooth" });
          }
          handleSnackBar(true, res.data.msg, "success");
        } else {
          handleSnackBar(true, res.data.msg, "success");
        }
      } else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error");
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  };

  const getCandidateById = async () => {
    try {
      let response = await getRequestWithToken(
        `admin/userbyid/?usertype=job_seeker&userid=${location.state?._id}`
      );
      setDataById(response.data.data);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(
        true,
        error?.response?.data?.msg || error?.message,
        "error"
      );
      setShowLoader(false);
    }
  };

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Email Campaign";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Email Campaign"));
  };

  const getTags = async () => {
    try {
      let response = await getRequestWithToken("admin/tags");
      response = response?.data;
      if (response.status === "Success") {
        let tags = response.data
          .filter((tag) => tag && tag !== "undefined")
          .map((tag) => ({
            label: tag.replace("_", " "),
            value: tag.replace("_", " "),
          }));

        setOptions(tags);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();

    if (location.state?._id) {
      getCandidateById();
    } else {
      setShowLoader(false);
    }

    if (location.state?._id) {
      const { candidate = {}, deleted = {} } = dataById;
      const {
        campaignName = "",
        templateId = "",
        campaignType = "",
        csvFile = "",
        bodyTextContent = "",
        templateLanguage = "",
      } = candidate;

      setInitialValues({
        campaignName,
        templateId,
        campaignType,
        csvFile,
        bodyTextContent,
        templateLanguage,
      });
      setEditForm(true);
    } else {
      setInitialValues({
        campaignName: "",
        templateId: "",
        campaignType: "",
        csvFile: "",
        bodyTextContent: "",
        templateLanguage: "",
        sendLater: false,
        scheduledTime: "",
        tag: "",
        // date: null,
        // time: null,
      });
      setEditForm(false);
    }
  }, [location.state, setInitialValues, dataById._id, isIncludesValue]);
  const fileRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleCreate = (inputValue, setFieldValue) => {
    setValue({ value: inputValue, label: inputValue });
    setFieldValue("tag", { value: inputValue, label: inputValue });
  };

  const [formNumber, setFormNumber] = useState(1);
  const [formPreview, setFormPreview] = useState(null);
  const [firstFormValues, setFirstFormValues] = useState({
    campaignName: "",
    tag: null,
    campaignType: "",
    scheduledTime: "",
    modeOfSend: "email",
  });

  const [loading, setLoading] = useState(null);

  const [secondFormValues, setSecondFormValues] = useState({
    dataSource: "",
    condition: "",
    startDate: null,
    endDate: null,
    csv: "",
    count: 0,
    eligibleCount: 0,
  });

  const datePickerRef = useRef(null);
  const onChange = (dates, setFieldValue) => {
    const [start, end] = dates;
    setFieldValue("startDate", start);
    setFieldValue("endDate", end);
    setFieldValue("count", 0);
    setFieldValue("eligibleCount", 0);
  };

  const [thirdFormValues, setThirdFormValues] = useState({
    templateId: "",
    templatePreview: "",
    addAttachement: false,
    fileType: "",
    file: "",
    from: "",
    cc: "",
    bcc: "",
    subject: "",
  });

  const initialBreadCrumbs = [
    { id: 1, label: "Campaign Details", completed: false },
    { id: 2, label: "Data source", completed: false },
    {
      id: 3,
      label: "Template",
      completed: false,
    },
    {
      id: 4,
      label: "Review",
      completed: false,
    },
  ];
  const [breadCrumbItems, setBreadCrumbItems] = useState(initialBreadCrumbs);

  const handleSubmitFirstForm = async (values) => {
    setCampaignNameIs(values.campaignName);
    setLinearLoader(true);
    const newFirstFormValues = {
      campaignName: values.campaignName,
      // tag: values.tag.value ? values.tag.value : newTagValue.value,
      tag: values.tag.value,
      campaignType: values.campaignType,
      scheduledTime: values.scheduledTime
        ? values.scheduledTime
        : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      modeOfSend: "email",
    };
    setFirstFormValues(newFirstFormValues);

    try {
      let res = await postRequest(`admin/campaign/create`, newFirstFormValues);

      if (res.data.status === "Success") {
        setLinearLoader(false);
        setFormSubmitted(false);
        // handleSnackBar(true, res.data.msg, "success");
        setFormNumber(2);
        setCampaignId(res?.data?.data?.campaignId);
      } else {
        setFormSubmitted(false);
        setLinearLoader(false);
        handleSnackBar(true, res.data.msg, "error");
      }
    } catch (err) {
      setFormSubmitted(false);
      setLinearLoader(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  };

  const checkCSV = (event, setFieldValue, setErrors) => {
    let csvFieldMissing = [];
    setErrors({ csvFile: "" });
    setEmptyCsvFile(false);
    const file = event.target.files[0];
    setFieldValue("csvFile", file);
    setCsvFile(file);
    if (!file) return;
    const reader = new FileReader();

    reader.onload = (e) => {
      const text = e.target.result;
      // console.log(text, "text");
      if (text.trim() === "") {
        setErrors({
          csvFile:
            "The CSV file you uploaded is empty. Please add data to the file and try again",
        });
        setEmptyCsvFile(true);
      } else {
        setCSVFileChecking(true);
        const wb = read(text, { type: "binary" });
        const sheets = wb.SheetNames;
        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
        const stringifiedData = rows.filter((entry) => {
          // Check if "to" and "subject" fields are present and not empty
          if (entry?.to && entry?.subject) return entry;
          else {
            csvFieldMissing.push(entry);
          }
        });
        if (csvFieldMissing.length > 0) {
          setErrors({
            csvFile:
              "Please check the CSV files for the missing fields and try again",
          });
          // fileRef.current.value = null;
        }
        if (csvFieldMissing.length === 0) {
          setErrors({
            csvFile: "",
          });
          setCSVFileChecking(false);
        }
      }
    };

    reader?.readAsText(file);
    // setInputKey(new Date());
  };

  const getCondition = async (value, setFieldValue) => {
    setLinearLoader(true);
    if (value === "CSV") {
      setDataSourceValue(value);
      setLinearLoader(false);
      return;
    }
    try {
      setDataSourceValue(value);
      let res = await getRequestWithToken(
        `admin/campaign/conditions?modeOfSend=email&source=${value}`
      );
      if (res.data.status === "Success") {
        setConditionsValue(res.data.data);
        // handleSnackBar(true, res?.data?.msg, "success");
        setLinearLoader(false);
      }
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setLinearLoader(false);
    }
  };

  const handleSubmitSecondForm = async (values, { setFieldValue }) => {
    let startingDate = values.startDate
      ? values.startDate.toISOString()
      : new Date(new Date().setFullYear(new Date().getFullYear() - 2));
    let endingDate = values.endDate
      ? values.endDate.toISOString()
      : new Date().toISOString();
    setFormSubmitted(true);
    setLoading(true);
    let formData = new FormData();
    formData = {
      modeOfSend: "email",
      campaignId: campaignId,
      dataSource: values.dataSource,
      condition: values.condition,
      dateCriteria: {
        from: startingDate,
        till: endingDate,
      },
    };
    try {
      let res = await patchRequestWithToken(
        "admin/campaign/check-count",
        formData
      );
      if (res.data.status === "Success") {
        setFormSubmitted(false);
        res = res?.data?.data;
        setFieldValue("count", res.actualUserCount);
        setFieldValue("eligibleCount", res.eligibleUsersCount);
        setLoading(false);
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setLoading(false);
    }
  };

  const handleSubmitThirdForm = async (values) => {
    setLinearLoader(true);
    const variables = {};
    variablesList.forEach((variable) => {
      const sanitizedVariable = sanitizeVariableName(variable);
      if (values[sanitizedVariable] !== undefined) {
        variables[`{{${variable}}}`] = values[sanitizedVariable] || "";
      } else {
        variables[`{{${variable}}}`] = values[variable] || "";
      }
    });

    const formattedData = {
      campaignId: campaignId,
      // campaignId: "CAMP1564",
      from: values.from,
      cc: values.cc,
      bcc: values.bcc,
      subject: values.subject,
      bodyTextContent: values.templatePreview,
      variables: variables,
    };

    try {
      let res = await patchRequestWithToken(
        "admin/campaign/template/email",
        formattedData
      );
      if (res.data.status === "Success") {
        setFormSubmitted(false);
        res = res?.data?.data;
        setLoading(false);
        setLinearLoader(false);
        setFormNumber(4);
        getPreview(campaignId);
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setLoading(false);
      setLinearLoader(false);
    }
  };

  const getPreview = async (campaignId) => {
    setLinearLoader(true);
    try {
      let res = await getRequestWithToken(
        `admin/campaign/campaign-id?campaignId=${campaignId}`
      );
      if (res.data.status === "Success") {
        setFormPreview(res.data.data);
        setLinearLoader(false);
      }
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setLinearLoader(false);
    }
  };

  const handleSubmitFourthForm = async (values) => {
    setLinearLoader(true);
    const formData = {
      campaignId: campaignId,
    };

    try {
      let res = await patchRequestWithToken(
        "admin/campaign/activate",
        formData
      );
      if (res.data.status === "Success") {
        setFormSubmitted(false);
        setLoading(false);
        setLinearLoader(false);
        handleSnackBar(true, res.data.msg, "success");
        setFormNumber(1);
        navigate("/email-campaign");
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setLoading(false);
      setLinearLoader(false);
    }
  };

  const handleCsvSubmit = async (values) => {
    setLinearLoader(true);
    setFormSubmitted(true);
    let formData = new FormData();

    formData.append("campaignId", campaignId);
    formData.append("campaignName", campaignNameIs);
    formData.append("csvFile", csvFile);
    formData.append(
      "params",
      JSON.stringify({
        emailParams: {
          from: values.from,
          bcc: values.bcc,
          cc: values.cc,
        },
      })
    );
    formData.append("bodyTextContent", values.templatePreview);

    try {
      let res = await postRequest("admin/campaign-schedule/csv", formData);

      if (res.data.status === "Success") {
        setFormSubmitted(false);
        if (!editForm) {
          // resetForm();

          // handleSnackBar(true, res.data.msg, "success");
          setFormNumber(4);
          setLinearLoader(false);
          getPreview(campaignId);
        } else {
          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate("/candidate");
          }, 1000);
          setLinearLoader(false);
        }
      } else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error");
        setLinearLoader(false);
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setLinearLoader(false);
    }
  };

  useEffect(() => {
    const completeForm = () => {
      if (formNumber < 1) return;
      setBreadCrumbItems((prevItems) =>
        prevItems.map((item) =>
          item.id === formNumber - 1 ? { ...item, completed: true } : item
        )
      );
    };
    completeForm();
  }, [formNumber]);

  const getVariables = (value, setFieldValue) => {
    setFieldValue("templatePreview", value);
    const regex = /\{{(\w+)}}/g;
    let variables = [];
    let match;

    while ((match = regex.exec(value)) !== null) {
      variables.push(match[1]);
    }

    setVariablesList(variables);

    return variables;
  };

  const getUserKeys = async (dataSource, setFieldValue) => {
    if (dataSource === "CSV") return;
    let source = dataSource?.toLowerCase();
    try {
      let response = await getRequestWithToken(
        `admin/campaign/user-keys?source=${source}`
      );
      response = response?.data;
      if (response.status === "Success") {
        setKeysValues(response.data);
        setFieldValue("userKeysData", response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (formNumber > 2) {
      getUserKeys(dataSourceValue);
    }
  }, [formNumber]);

  const functionTemplate = (errors) => {
    const proceedToNextStep = () => {
      setLinearLoader(true);
      setTimeout(() => {
        setLinearLoader(false);
        setFormNumber(3);
      }, 500);
    };

    if (dataSourceValue === "CSV") {
      if (csvFile) {
        console.log("CSV file present");
        proceedToNextStep();
        setCsvError(false);
      } else {
        console.log("CSV file missing");
        // errors.csvFile = "Please upload a CSV file";
        // errors.csvFile = "Please upload a CSV file";
        setCsvError(true);
      }
    } else {
      if (!errors.condition && !errors.startDate) {
        console.log("No errors in condition or startDate");
        proceedToNextStep();
        setCsvError(true);
      }
    }
  };

  const csvOnChange = (event) => {
    if (dataSourceValue === "CSV") {
      if (csvFile) {
        console.log("CSV file present");

        setCsvError(false);
      } else {
        console.log("CSV file missing");

        setCsvError(true);
      }
    }
  };

  const sanitizeVariableName = (name) => {
    // Remove non-alphanumeric characters except underscores and replace them with underscores
    let sanitized = name.replace(/[^a-zA-Z0-9]/g, "_");

    if (!/^[a-zA-Z]/.test(sanitized)) {
      sanitized = `var_${sanitized}`;
    }

    return sanitized;
  };

  const extendedSchema =
    dataSourceValue !== "CSV"
      ? EmailCampaignThirdFormValidationSchema.shape({
        subject: yup.string().trim().required("Subject is required"),
        ...(variablesList || []).reduce((schema, variable) => {
          const sanitizedVariable = sanitizeVariableName(variable);
          schema[sanitizedVariable] = yup
            .string()
            .required(`This field is required`);
          return schema;
        }, {}),
      })
      : EmailCampaignThirdFormValidationSchema;

  const DeleteCampaign = async () => {
    try {
      let response = await deleteRequest(
        `admin/campaign?campaignId=${campaignId}`
      );
      if (response.data.status === "Success") {
        handleSnackBar(true, "Campaign Canceled", "success");
        navigate("/email-campaign");
      } else {
        handleSnackBar(true, response.data.msg, "error");
      }
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  };

  return isIncludesValue ? (
    <>
      {showLoader ? (
        <ReusableLoader />
      ) : (
        <Box className="create-whatsapp-campaign-section" ref={scrollTargetRef}>
          <Container>
            <Box className="create-whatsapp-campaign">
              <Box className="headingPlusBackBtn">
                <BackBtn />
                <h1 className="formHeadings" id="topEle">
                  New Email Campaign
                </h1>
              </Box>

              <Breadcrumbs
                aria-label="breadcrumb"
                style={{ margin: "20px 0px" }}
                separator=">"
              >
                {breadCrumbItems?.map((item) => (
                  <MuiLink
                    key={item?.id}
                    underline="hover"
                    color={`${item?.id === formNumber ? "text.primary" : "inherit"
                      }`}
                    sx={{ display: "flex", alignItems: "center" }}
                  // onClick={() => {
                  //   item?.completed && setFormNumber(item?.id);
                  // }}
                  >
                    {item?.completed && (
                      <DoneIcon
                        color={`${item?.id === formNumber ? "text.primary" : "inherit"
                          }`}
                      />
                    )}
                    {item?.label}
                  </MuiLink>
                ))}
              </Breadcrumbs>

              {linearLoader ? (
                <LinearProgress
                  style={{
                    width: "100%",
                    position: "absolute",
                    left: "0",
                    top: "66px",
                    zIndex: "999",
                  }}
                />
              ) : (
                ""
              )}
              {formNumber === 1 && (
                <Box className="create-whatsapp-campaign-form-wrapper">
                  <Formik
                    initialValues={firstFormValues}
                    validationSchema={EmailCampaignFirstFormValidationSchema}
                    onSubmit={handleSubmitFirstForm}
                    enableReinitialize
                  >
                    {({ errors, values, setFieldValue, resetForm }) => (
                      <Form className="create-whatsapp-campaign-form">
                        <Box className="backgroundStyle">
                          <Grid
                            container
                            spacing={2}
                            className="cstmFormGridEmployer spaceBetween"
                          >
                            <Grid item xs={12} sm={6} md={6}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box className="form-control">
                                    <label
                                      className="form-label"
                                      htmlFor="campaignName"
                                    >
                                      {" "}
                                      Campaign Name{" "}
                                      <span className="must-fill-fields">
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      id="campaignName"
                                      type="text"
                                      placeholder="Enter Campaign Name"
                                      name="campaignName"
                                      className="form-input"
                                      size="small"
                                    />
                                    {errors.campaignName && (
                                      <Box className="form-error">
                                        {errors.campaignName}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                  <Box className="form-control">
                                    <label className="form-label" htmlFor="tag">
                                      Campaign Tag
                                      <span className="must-fill-fields">
                                        *
                                      </span>
                                    </label>
                                    <CreatableSelect
                                      isClearable
                                      isDisabled={isLoading}
                                      isLoading={isLoading}
                                      onChange={(newValue) => {
                                        setValue(newValue);
                                        setFieldValue("tag", newValue);
                                      }}
                                      onCreateOption={(inputValue) =>
                                        handleCreate(inputValue, setFieldValue)
                                      }
                                      options={options}
                                      value={value}
                                      placeholder="Select or create a campaign tag"
                                    />
                                    {errors.tag && (
                                      <Box className="form-error">
                                        {errors.tag}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                  <Box className="form-control">
                                    <label
                                      className="form-label"
                                      htmlFor="campaignType"
                                    >
                                      {" "}
                                      Campaign Type{" "}
                                      <span className="must-fill-fields">
                                        *
                                      </span>
                                    </label>
                                    <Select
                                      className="cstmSelectBox"
                                      name="campaignType"
                                      id="campaignType"
                                      value={values.campaignType}
                                      onChange={(event) => {
                                        setFieldValue(
                                          "campaignType",
                                          event.target.value
                                        );
                                      }}
                                      displayEmpty
                                      renderValue={(selected) =>
                                        selected
                                          ? selected
                                          : "Select campaignType"
                                      }
                                    >
                                      <MenuItem value="" disabled>
                                        Select campaignType
                                      </MenuItem>
                                      <MenuItem value="marketing">
                                        Marketing
                                      </MenuItem>
                                      <MenuItem value="utility">
                                        Utility
                                      </MenuItem>
                                    </Select>

                                    {errors.campaignType && (
                                      <Box className="form-error">
                                        {errors.campaignType}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sm={5} md={5}>
                              <Box
                                style={{
                                  display: "flex",
                                  // gap: "5px",
                                  marginTop: "-7px",
                                  alignItems: "center",
                                }}
                              >
                                <Typography className="sendLater">
                                  Send Later
                                </Typography>

                                <Switch
                                  className="saveLaterSwitch"
                                  checked={values.sendLater}
                                  name="sendLater"
                                  // onChange={() =>
                                  //   setFieldValue(
                                  //     "sendLater",
                                  //     !values.sendLater
                                  //   )
                                  // }
                                  onChange={async () => {
                                    const newSendLaterValue = !values.sendLater;
                                    await setFieldValue(
                                      "sendLater",
                                      newSendLaterValue
                                    );
                                    if (values.sendLater) {
                                      setFieldValue("scheduledTime", null);
                                    }
                                  }}
                                />
                              </Box>
                              {values.sendLater && (
                                <Grid container>
                                  <Grid item xs={12} sm={12} md={12}>
                                    <Box className="form-control flexRow">
                                      <label
                                        className="form-label"
                                        htmlFor="date"
                                      >
                                        Date and Time
                                      </label>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DateTimePicker
                                          label=""
                                          value={values.scheduledTime}
                                          className="cstmDateTimePicker"
                                          onChange={(newValue) => {
                                            const utcDate = new Date(
                                              newValue
                                            ).toISOString();

                                            setFieldValue(
                                              "scheduledTime",
                                              utcDate
                                            );
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Box>
                                    {errors.scheduledTime && (
                                      <Box className="form-error">
                                        {errors.scheduledTime}
                                      </Box>
                                    )}
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Box>

                        <Box className="form-button-section">
                          <Button
                            className="form-button"
                            variant="contained"
                            onClick={() => {
                              resetForm();
                            }}
                            color="error"
                          >
                            Cancel
                          </Button>

                          <Button
                            className="form-button"
                            variant="contained"
                            type="submit"
                            color="success"
                            disabled={formSubmitted}
                          >
                            Next
                          </Button>
                        </Box>
                        <FocusError handleSnackBar={handleSnackBar} />
                      </Form>
                    )}
                  </Formik>
                </Box>
              )}

              {formNumber === 2 && (
                <Box className="create-whatsapp-campaign-form-wrapper">
                  <Formik
                    initialValues={secondFormValues}
                    validationSchema={EmailCampaignSecondFormValidationSchema}
                    onSubmit={handleSubmitSecondForm}
                    enableReinitialize
                  >
                    {({
                      errors,
                      values,
                      setFieldValue,
                      resetForm,
                      setErrors,
                    }) => (
                      <Form className="create-whatsapp-campaign-form">
                        <Box className="backgroundStyle">
                          <Grid
                            container
                            spacing={2}
                            className="cstmFormGridEmployer"
                          >
                            <Grid item xs={12} sm={6} md={4}>
                              <Box className="form-control">
                                <label
                                  className="form-label"
                                  htmlFor="dataSource"
                                >
                                  Data Source
                                  <span className="must-fill-fields">*</span>
                                </label>

                                <Select
                                  className="cstmSelectBox"
                                  name="dataSource"
                                  id="dataSource"
                                  value={values.dataSource}
                                  onChange={async (event) => {
                                    const { value } = event.target;

                                    // console.log("valueeeee: ", value);
                                    await setFieldValue("dataSource", value);
                                    await setFieldValue("condition", "");
                                    await setFieldValue("count", 0);
                                    await setFieldValue("eligibleCount", 0);
                                    await setFieldValue("startDate", null);
                                    await setFieldValue("endDate", null);
                                    await getCondition(value);
                                    await getUserKeys(value, setFieldValue);
                                  }}
                                  // onChange={(event) => {
                                  //   setFieldValue(
                                  //     "dataSource",
                                  //     event.target.value
                                  //   );
                                  //   getCondition(event.target.value);
                                  //   getUserKeys(
                                  //     event.target.value,
                                  //     setFieldValue
                                  //   );
                                  // }}
                                  displayEmpty
                                  renderValue={(selected) =>
                                    selected ? selected : "Select Data Source"
                                  }
                                >
                                  <MenuItem value="" disabled>
                                    Data Source
                                  </MenuItem>
                                  <MenuItem value="CSV">CSV</MenuItem>
                                  <MenuItem value="Employer">Employer</MenuItem>
                                  <MenuItem value="Candidate">
                                    Candidate
                                  </MenuItem>
                                </Select>
                                {errors.dataSource && (
                                  <Box className="form-error">
                                    {errors.dataSource}
                                  </Box>
                                )}
                              </Box>
                            </Grid>

                            {(values.dataSource === "Employer" ||
                              values.dataSource === "Candidate") && (
                                <Grid item xs={12} sm={6} md={4}>
                                  <Box className="form-control">
                                    <label
                                      className="form-label"
                                      htmlFor="condition"
                                    >
                                      Condition
                                      <span className="must-fill-fields">*</span>
                                    </label>
                                    <Select
                                      disabled={values.dataSource === "CSV"}
                                      className="cstmSelectBox"
                                      name="condition"
                                      id="condition"
                                      value={values.condition}
                                      onChange={async (event) => {
                                        const { value } = event.target;
                                        await setFieldValue("condition", value);
                                        await setFieldValue("count", 0);
                                        await setFieldValue("eligibleCount", 0);
                                        // await setFieldValue("startDate", null);
                                        // await setFieldValue("endDate", null);
                                      }}
                                      displayEmpty
                                      renderValue={(selected) =>
                                        selected ? selected : "Select Condition"
                                      }
                                    >
                                      <MenuItem value="" disabled>
                                        condition
                                      </MenuItem>
                                      {conditionsValue &&
                                        conditionsValue.map((condition) => (
                                          <MenuItem
                                            key={condition}
                                            value={condition}
                                          >
                                            {condition}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.condition && (
                                      <Box className="form-error">
                                        {errors.condition}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              )}

                            {(values.dataSource === "Employer" ||
                              values.dataSource === "Candidate") && (
                                <Grid item xs={12} sm={6} md={4}>
                                  <Box className="form-control">
                                    <label className="form-label">
                                      Date Range
                                    </label>

                                    <Box
                                      style={{
                                        border: "1px solid #c4c4c4",
                                        borderRadius: "5px",
                                        display: "flex",
                                        gap: "10px",
                                        padding: "5px",
                                        alignItems: "center",
                                        background: "#ffffff",
                                      }}
                                    >
                                      <DatePicker
                                        selected={values.startDate}
                                        onChange={(dates) => {
                                          onChange(dates, setFieldValue);
                                        }}
                                        startDate={values.startDate}
                                        endDate={values.endDate}
                                        selectsRange
                                        ref={datePickerRef}
                                        disabled={values.dataSource === "CSV"}
                                        placeholderText="Select Date Range"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        dateFormat="MMMM d, yyyy"
                                      />
                                      <CalendarMonthIcon
                                        onClick={() =>
                                          datePickerRef.current.setOpen(true)
                                        }
                                      />
                                    </Box>

                                    {/* {errors.startDate && (
                                  <Box className="form-error">
                                    {errors.startDate}
                                  </Box>
                                )} */}
                                  </Box>
                                </Grid>
                              )}

                            {dataSourceValue === "CSV" && (
                              <Grid item xs={12} sm={6} md={4}>
                                <Box className="form-control">
                                  <label className="form-label">
                                    Upload CSV{" "}
                                    <span className="must-fill-fields">*</span>
                                  </label>
                                  <input
                                    // key={inputKey}
                                    disabled={
                                      values.dataSource !== "CSV" ||
                                      values.dataSource === ""
                                    }
                                    type="file"
                                    name="csvFile"
                                    className="mt-3 input-width upload_button"
                                    id="csvFile"
                                    onChange={(event) => {
                                      checkCSV(event, setFieldValue, setErrors);
                                    }}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    style={{
                                      border: "1px solid #ccc",
                                      borderRadius: "4px",
                                      padding: "8px",
                                      backgroundColor: "#f7f7f7",
                                      color: "#333",
                                      fontSize: "14px",
                                    }}
                                    ref={fileRef}
                                  />
                                  {errors.csvFile && (
                                    <Box className="form-error">
                                      {errors.csvFile}
                                    </Box>
                                  )}
                                  {!csvFile && (
                                    <Box className="form-error">
                                      Please upload a CSV file
                                    </Box>
                                  )}
                                </Box>
                              </Grid>
                            )}

                            <Grid item xs={3} sm={3} md={4}></Grid>
                            <Grid item xs={3} sm={3} md={4}></Grid>
                            {(values.dataSource === "Employer" ||
                              values.dataSource === "Candidate") && (
                                <>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <Box className="form-control">
                                      <Button
                                        className="form-button"
                                        variant="contained"
                                        color="success"
                                        type="submit"
                                        style={{
                                          width: "100%",
                                          marginLeft: "auto",
                                        }}
                                        disabled={
                                          values.dataSource === "CSV" ||
                                          values.dataSource === ""
                                        }
                                      // onClick={() => CheckCount(setFieldValue)}
                                      >
                                        Check Count
                                      </Button>
                                    </Box>
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={12}>
                                    <Grid container>
                                      <Grid item xs={12} sm={4} md={4}></Grid>
                                      <Grid item xs={12} sm={4} md={4}>
                                        <Box
                                          style={{
                                            background: "white",
                                            padding: "10px",
                                            border: "1px solid lightgray",
                                            borderRadius: "10px",
                                            display: "flex",
                                            gap: "10px",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Typography>
                                            Number of total user
                                          </Typography>
                                          {loading ? (
                                            <CircularProgress />
                                          ) : (
                                            <Typography
                                              style={{
                                                fontSize: "40px",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {values.eligibleCount}
                                            </Typography>
                                          )}
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} sm={4} md={4}>
                                        <Box
                                          style={{
                                            background: "white",
                                            padding: "10px",
                                            border: "1px solid lightgray",
                                            borderRadius: "10px",
                                            display: "flex",
                                            gap: "10px",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Typography>
                                            Number of users eligible for campaign
                                          </Typography>
                                          {loading ? (
                                            <CircularProgress />
                                          ) : (
                                            <Typography
                                              style={{
                                                fontSize: "40px",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {values.count}
                                            </Typography>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                          </Grid>
                        </Box>

                        <Box className="form-button-section">
                          <Button
                            className="form-button"
                            variant="contained"
                            onClick={() => {
                              setOpenDeleteModal(true);
                            }}
                            color="error"
                          >
                            Cancel
                          </Button>

                          <Button
                            className="form-button"
                            variant="contained"
                            color="success"
                            disabled={
                              dataSourceValue === "CSV"
                                ? emptyCsvFile || CSVFileChecking
                                : values.count === 0
                            }
                            onClick={() => functionTemplate(errors)}
                          >
                            Next
                          </Button>
                        </Box>
                        <FocusError handleSnackBar={handleSnackBar} />
                      </Form>
                    )}
                  </Formik>
                </Box>
              )}

              {formNumber === 3 && (
                <Box
                  className="create-whatsapp-campaign-form-wrapper"
                  style={{ position: "relative" }}
                >
                  <Formik
                    initialValues={thirdFormValues}
                    validationSchema={extendedSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      setShowErrors(true);
                      if (dataSourceValue === "CSV") {
                        handleCsvSubmit(values);
                      } else {
                        handleSubmitThirdForm(values);
                      }
                      setSubmitting(false);
                    }}
                    enableReinitialize
                  >
                    {({
                      errors,
                      values,
                      setFieldValue,
                      resetForm,
                      isSubmitting,
                      submitForm,
                    }) => (
                      <Form className="create-whatsapp-campaign-form">
                        <Box className="backgroundStyle">
                          <Grid
                            container
                            spacing={2}
                            className="cstmFormGridEmployer"
                          >
                            <Grid item xs={12} sm={12} md={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Grid container gap={2}>
                                    <Grid item xs={12} sm={6} md={5}>
                                      <Box className="form-control">
                                        <label
                                          className="form-label"
                                          htmlFor="from"
                                        >
                                          From
                                          <span className="must-fill-fields">
                                            *
                                          </span>
                                        </label>

                                        <Field
                                          id="from"
                                          type="email"
                                          placeholder="Enter from"
                                          name="from"
                                          className="form-input"
                                          size="small"
                                        />
                                        {errors.from && (
                                          <Box className="form-error">
                                            {errors.from}
                                          </Box>
                                        )}
                                      </Box>
                                    </Grid>

                                    {dataSourceValue !== "CSV" && (
                                      <>
                                        <Grid item xs={12} sm={6} md={5}>
                                          <Box className="form-control">
                                            <label
                                              className="form-label"
                                              htmlFor="cc"
                                            >
                                              CC
                                            </label>
                                            <Field
                                              id="cc"
                                              type="email"
                                              placeholder="Enter CC"
                                              name="cc"
                                              className="form-input"
                                              size="small"
                                            />
                                            {errors.cc && (
                                              <Box className="form-error">
                                                {errors.cc}
                                              </Box>
                                            )}
                                          </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5}>
                                          <Box className="form-control">
                                            <label
                                              className="form-label"
                                              htmlFor="bcc"
                                            >
                                              BCC
                                            </label>
                                            <Field
                                              id="bcc"
                                              type="email"
                                              placeholder="Enter BCC"
                                              name="bcc"
                                              className="form-input"
                                              size="small"
                                            />
                                            {errors.bcc && (
                                              <Box className="form-error">
                                                {errors.bcc}
                                              </Box>
                                            )}
                                          </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5}>
                                          <Box className="form-control">
                                            <label
                                              className="form-label"
                                              htmlFor="subject"
                                            >
                                              Subject
                                              <span className="must-fill-fields">
                                                *
                                              </span>
                                            </label>

                                            <Field
                                              id="subject"
                                              type="text"
                                              placeholder="Enter Subject"
                                              name="subject"
                                              className="form-input"
                                              size="small"
                                            />
                                            {errors.subject && (
                                              <Box className="form-error">
                                                {errors.subject}
                                              </Box>
                                            )}
                                          </Box>
                                        </Grid>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box className="form-control">
                                    <label
                                      className="form-label"
                                      htmlFor="templateId"
                                    >
                                      Select Template
                                      <span className="must-fill-fields">
                                        *
                                      </span>
                                    </label>
                                    <textarea
                                      id="templateId"
                                      name="templateId"
                                      value={values.templateId}
                                      onChange={(event) =>
                                        setFieldValue(
                                          "templateId",
                                          event.target.value
                                        )
                                      }
                                      className="form-input"
                                      rows={8}
                                      cols={50}
                                    />

                                    <Button
                                      className="form-button"
                                      style={{ width: "150px" }}
                                      variant="contained"
                                      onClick={() => {
                                        const textAreaValue = values.templateId;
                                        getVariables(
                                          textAreaValue,
                                          setFieldValue
                                        );
                                      }}
                                      color="primary"
                                    >
                                      Preview
                                    </Button>

                                    {errors.templateId && (
                                      <Box className="form-error">
                                        {errors.templateId}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>

                                {variablesList && (
                                  <Box
                                    className="form-control"
                                    style={{ padding: "16px 0px 0px 16px" }}
                                  >
                                    <Box
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <label className="form-label">
                                        Template Parameters
                                      </label>
                                      {dataSourceValue !== "CSV" && (
                                        <span className="must-fill-fields">
                                          *
                                        </span>
                                      )}
                                    </Box>
                                    {variablesList.map((variable, index) => {
                                      const sanitizedVariable =
                                        sanitizeVariableName(variable);
                                      return (
                                        <Box key={index}>
                                          <Box
                                            style={{
                                              display: "flex",
                                              gap: "10px",
                                              alignItems: "center",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <label
                                              className="form-label"
                                              style={{ minWidth: "130px" }}
                                            >
                                              {`{${variable}}`}
                                            </label>
                                            <Select
                                              style={{ width: "300px" }}
                                              className="cstmSelectBox"
                                              disabled={
                                                dataSourceValue === "CSV"
                                              }
                                              name={sanitizedVariable}
                                              id={sanitizedVariable}
                                              value={
                                                values[sanitizedVariable] || ""
                                              }
                                              onChange={(event) => {
                                                setFieldValue(
                                                  sanitizedVariable,
                                                  event.target.value
                                                );
                                              }}
                                              displayEmpty
                                              renderValue={(selected) =>
                                                selected
                                                  ? selected
                                                  : "Select variable key"
                                              }
                                            >
                                              <MenuItem value="" disabled>
                                                Select variable key
                                              </MenuItem>

                                              {keysValues &&
                                                keysValues.map(
                                                  (item, index) => (
                                                    <MenuItem
                                                      value={item}
                                                      key={index}
                                                    >
                                                      {item}
                                                    </MenuItem>
                                                  )
                                                )}
                                            </Select>
                                          </Box>
                                          {showErrors &&
                                            errors[sanitizedVariable] && (
                                              <Box
                                                className="form-error"
                                                style={{ marginLeft: "140px" }}
                                              >
                                                {errors[sanitizedVariable]}
                                              </Box>
                                            )}
                                        </Box>
                                      );
                                    })}
                                  </Box>
                                )}
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              <label
                                className="form-label"
                                htmlFor="templateId"
                                style={{ fontSize: "14px" }}
                              >
                                Preview
                              </label>
                              <Box
                                style={{
                                  margin: "7px 10px",
                                  border: "1px solid lightgray",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  minHeight: "400px",
                                }}
                              >
                                {/* {values.templatePreview} */}
                                {parse(values.templatePreview)}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box className="form-button-section">
                          <Button
                            className="form-button"
                            variant="contained"
                            onClick={() => {
                              setOpenDeleteModal(true);
                            }}
                            color="error"
                          >
                            Cancel
                          </Button>

                          <Button
                            className="form-button"
                            variant="contained"
                            type="submit"
                            color="success"
                            onClick={() => {
                              setShowErrors(true);
                            }}
                            disabled={isSubmitting}
                          // disabled={formSubmitted || uploadErrors.fileUpload}
                          >
                            Next
                          </Button>
                        </Box>
                        <FocusError handleSnackBar={handleSnackBar} />
                      </Form>
                    )}
                  </Formik>
                </Box>
              )}

              {formNumber === 4 && (
                <Box
                  className="create-whatsapp-campaign-form-wrapper"
                  style={{ position: "relative" }}
                >
                  <Formik
                    initialValues={thirdFormValues}
                    // validationSchema={CreateWhatsAppCampaignValidationSchema}
                    onSubmit={handleSubmitFourthForm}
                    enableReinitialize
                  >
                    {({ errors, values, setFieldValue, resetForm }) => (
                      <Form className="create-whatsapp-campaign-form">
                        <Box className="backgroundStyle">
                          <Grid
                            container
                            spacing={2}
                            className="cstmFormGridEmployer"
                          >
                            <Grid item xs={12} sm={12} md={4}>
                              {linearLoader ? (
                                <Skeleton
                                  sx={{ height: 15 }}
                                  animation="wave"
                                  variant="rounded"
                                />
                              ) : (
                                <Box
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <Typography>Campaign Name :</Typography>
                                  <Typography>
                                    {formPreview?.campaignName}
                                  </Typography>
                                </Box>
                              )}
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                              {linearLoader ? (
                                <Skeleton
                                  sx={{ height: 15 }}
                                  animation="wave"
                                  variant="rounded"
                                />
                              ) : (
                                <Box
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <Typography>Campaign Type :</Typography>
                                  <Typography>
                                    {formPreview?.campaignType}
                                  </Typography>
                                </Box>
                              )}
                            </Grid>

                            <Grid item xs={12} sm={12} md={4}>
                              {linearLoader ? (
                                <Skeleton
                                  sx={{ height: 15 }}
                                  animation="wave"
                                  variant="rounded"
                                />
                              ) : (
                                <Box
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <Typography>Tags :</Typography>
                                  <Typography>{formPreview?.tag}</Typography>
                                </Box>
                              )}
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            spacing={2}
                            className="cstmFormGridEmployer"
                          >
                            <Grid item xs={12} sm={12} md={12}>
                              {linearLoader ? (
                                <Skeleton
                                  sx={{ height: 15 }}
                                  animation="wave"
                                  variant="rounded"
                                />
                              ) : (
                                <Box
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <Typography>
                                    Campaign Date & Time :
                                  </Typography>
                                  <Typography>
                                    {new Date(
                                      formPreview?.scheduledDateOrTime
                                    ).toLocaleString("en-US", {
                                      timeZone: "Asia/Kolkata",
                                    })}
                                  </Typography>
                                </Box>
                              )}
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              {linearLoader ? (
                                <Skeleton
                                  sx={{ height: 15 }}
                                  animation="wave"
                                  variant="rounded"
                                />
                              ) : (
                                <Box
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <Typography>Number of users :</Typography>
                                  <Typography>
                                    {formPreview?.numberOfUploads}
                                  </Typography>
                                </Box>
                              )}
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              {linearLoader ? (
                                <Skeleton
                                  sx={{ height: "200px" }}
                                  animation="wave"
                                  variant="rounded"
                                />
                              ) : (
                                <Box
                                  style={{
                                    border: "1px solid lightgray",
                                    padding: "10px",
                                    borderRadius: "10px",
                                    minHeight: "200px",
                                  }}
                                >
                                  {formPreview?.emailParams?.bodyTextContent
                                    ? parse(
                                      formPreview?.emailParams
                                        ?.bodyTextContent
                                    )
                                    : ""}
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        </Box>

                        <Box className="form-button-section">
                          <Button
                            className="form-button"
                            variant="contained"
                            onClick={() => {
                              setOpenDeleteModal(true);
                            }}
                            color="error"
                          >
                            Cancel
                          </Button>

                          <Button
                            className="form-button"
                            variant="contained"
                            type="submit"
                            color="success"
                          // disabled={formSubmitted || uploadErrors.fileUpload}
                          >
                            Save and Schedule
                          </Button>
                        </Box>
                        <FocusError handleSnackBar={handleSnackBar} />
                      </Form>
                    )}
                  </Formik>
                </Box>
              )}
            </Box>
          </Container>

          <SureYouWantToDeletModal
            modalTitle=""
            dialogBody="Are you sure you want to Cancel this Campaign?"
            buttonText="Yes"
            openDeleteModal={openDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            onYesClick={() => {
              DeleteCampaign();
            }}
          />
        </Box>
      )}
    </>
  ) : (
    <Unauthorized />
  );
};
export default CreateEmail;
