import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DonutSmallTwoToneIcon from "@mui/icons-material/DonutSmallTwoTone";
// import WorkIcon from '@mui/icons-material/Work';
import LocalLibraryTwoToneIcon from "@mui/icons-material/LocalLibraryTwoTone";
import ThumbDownAltTwoToneIcon from "@mui/icons-material/ThumbDownAltTwoTone";
import ThumbUpAltTwoToneIcon from "@mui/icons-material/ThumbUpAltTwoTone";
import AppSettingsAltTwoToneIcon from "@mui/icons-material/AppSettingsAltTwoTone";
import QrCodeScannerTwoToneIcon from "@mui/icons-material/QrCodeScannerTwoTone";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import QrCodeIcon from "@mui/icons-material/QrCode";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import HailTwoToneIcon from "@mui/icons-material/HailTwoTone";
import BoyTwoToneIcon from "@mui/icons-material/BoyTwoTone";
import EmojiPeopleTwoToneIcon from "@mui/icons-material/EmojiPeopleTwoTone";
import Person3TwoToneIcon from "@mui/icons-material/Person3TwoTone";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import CampaignIcon from "@mui/icons-material/Campaign";
import AssignmentIcon from "@mui/icons-material/Assignment";
import HandshakeIcon from "@mui/icons-material/Handshake";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import ChatIcon from "@mui/icons-material/Chat";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LinkIcon from '@mui/icons-material/Link';
// import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail"; // import PersonIcon from '@mui/icons-material/Person';
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link } from "react-router-dom";
// import { DarkModeContext } from "../../context/darkModeContext";
// import { useContext } from "react";
import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { TreeView } from "@mui/x-tree-view";
const Sidebar = () => {
  const userType = localStorage.getItem("user_type");
  const [showJobSettings, setShowJobSettings] = useState(false);
  const [showCampaign, setShowCampaign] = useState(false);
  const [showTaskOptions, setShowTaskOptions] = useState(false);
  const [presentResourceNames, setPresentResourceNames] = useState([]);

  const isResourcePresent = () => {
    const storedData = JSON.parse(localStorage.getItem("roleData"));
    const userData = storedData[0];
    const resourceNames = userData.resources.map(
      (resource) => resource.resource
    );
    localStorage.setItem("resourceNames", JSON.stringify(resourceNames));
    return resourceNames;
  };

  useEffect(() => {
    const resourceNamesArray = isResourcePresent();
    setPresentResourceNames(resourceNamesArray);
  }, []);

  const isAnyQrOptionAuthorized = presentResourceNames.some((resource) =>
    ["Qr stats", "Manage Qr", "Generate Qr"].includes(resource)
  );

  const isAnyJobsOptionAuthorized = presentResourceNames.some((resource) =>
    [
      "All Jobs",
      "Rejected Jobs",
      "Approved Jobs",
      "Job Category",
      "Job Title",
      "Pending Jobs",
    ].includes(resource)
  );

  const isAnyJobsSettingOptionAuthorized = presentResourceNames.some(
    (resource) =>
      [
        "Job Category",
        "Job Title",
        "Job Sectors",
        "Job Occupations",
        "Job Roles",
        "New",
      ].includes(resource)
  );

  const isAnyUsersOptionAuthorized = presentResourceNames.some((resource) =>
    ["Candidate", "Employer", "Partner", "Job Sahayak", "Recruiter"].includes(resource)
  );

  const isSeoOptionAuthorized = presentResourceNames.some((resource) =>
    ["SEO"].includes(resource)
  );

  const isAnyRolesOptionAuthorized = presentResourceNames.some((resource) =>
    ["Manage Roles", "Config Roles"].includes(resource)
  );

  const isAnyJobSevaOptionAuthorized = presentResourceNames.some((resource) =>
    ["Jobseva"].includes(resource)
  );

  const isAnyMarketingAuthorized = presentResourceNames.some((resource) =>
    ["Marketing", "Whatsapp Campaign", "Email Campaign", "Notifcation Campaign", "Banner Campaign"].includes(resource)
  );

  const isQueriesAuthorized = presentResourceNames.some((resource) =>
    ["Contact Queries"].includes(resource)
  );

  const isTransactionsAuthorized = presentResourceNames.some((resource) =>
    ["Payment Details"].includes(resource)
  );

  const isUtilityPresent = presentResourceNames.some((resource) =>
    ["Utility"].includes(resource)
  );

  const AdminSideBar = () => {
    return (
      <>
        {/* <div className="top">
       
      </div> */}
        <div className="center">
          <ul>
            <Box className="nav-sub-sections">
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                {/* <li className={!presentResourceNames.includes("Dashboard") ? "unauthorized" : ""}> */}
                <li>
                  <DonutSmallTwoToneIcon className="icon " />
                  <span className="disable">Dashboard</span>
                </li>
              </Link>
            </Box>
            {isAnyJobsOptionAuthorized && <p className="title">Jobs</p>}
            <Box className="nav-sub-sections ">
              <Link to="/all-jobs" style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("All Jobs")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <LocalLibraryTwoToneIcon className="icon " />
                  <span className="disable">All Jobs</span>
                </li>
              </Link>
              <Link to="/rejected-jobs" style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("Rejected Jobs")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <ThumbDownAltTwoToneIcon />
                  <span>Rejected Jobs</span>
                </li>
              </Link>
              <Link to="#" style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("Approved Jobs")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <ThumbUpAltTwoToneIcon className="icon disabled" />
                  <span className="disabled">Approved Jobs</span>
                </li>
              </Link>

              <li style={{ display: "flex", flexDirection: "column" }}>
                {isAnyJobsSettingOptionAuthorized && (
                  <Box
                    onClick={() => {
                      setShowJobSettings(!showJobSettings);
                    }}
                    sx={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <AppSettingsAltTwoToneIcon className="icon" />
                    </Box>
                    <span style={{ display: "inline-block" }}>
                      Job Settings
                    </span>
                  </Box>
                )}

                {showJobSettings && (
                  <Grid container>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}>
                      <Link
                        to="/job-categories"
                        style={{ textDecoration: "none" }}
                      >
                        <Box display="flex" alignItems="center">
                          <FiberManualRecordIcon
                            sx={{
                              fontSize: "14px !important",
                              color: "#35035d",
                            }}
                          />
                          <Typography
                            fontSize="16px"
                            sx={{ width: "100%", color: "#000" }}
                            textAlign="left"
                          >
                            Job Categories
                          </Typography>
                        </Box>
                      </Link>
                    </Grid>
                  </Grid>
                )}
              </li>
            </Box>

            {isUtilityPresent && <p className="title">Utility</p>}
            <Box className="nav-sub-sections ">
              <Link to="/url-shortener" style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("URL Shortener")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <LinkIcon className="icon " />
                  <span className="disable">URL Shortener</span>
                </li>
              </Link>
            </Box>

            {isAnyQrOptionAuthorized && <p className="title">QR</p>}
            <Box className="nav-sub-sections">
              <Link to="#" style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("Qr stats")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <QueryStatsIcon className="icon disabled" />
                  <span className="disabled">Stats</span>
                </li>
              </Link>
              <Link style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("Manage Qr")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <QrCodeScannerTwoToneIcon className="icon disabled" />
                  <span className="disabled">Manage QR</span>
                </li>
              </Link>
              <Link style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("Generate Qr")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <QrCodeIcon className="icon disabled" />
                  <span className="disabled">Generate QR</span>
                </li>
              </Link>
            </Box>
            {(isAnyMarketingAuthorized || isSeoOptionAuthorized) && (
              <p className="title">Marketing</p>
            )}
            {(isAnyMarketingAuthorized || isSeoOptionAuthorized) && (
              <Box className="nav-sub-sections ">
                {isAnyMarketingAuthorized && (
                  <TreeView
                    aria-label="file system navigator"
                    sx={{
                      flexGrow: 1,
                      maxWidth: 400,
                      overflowY: "auto",
                    }}
                    // defaultCollapseIcon={<ExpandMoreIcon />}
                    // defaultExpandIcon={<ChevronRightIcon />}
                    className="innerTreView"
                  >
                    <AppSettingsAltTwoToneIcon
                      className="icon"
                      style={{ marginTop: "10px", fontSize: "1.8rem" }}
                    />
                    <TreeItem
                      className="treeViewNewBlock"
                      nodeId="1"
                      label="Campaign"
                    >
                      {presentResourceNames.includes("Whatsapp Campaign") &&
                        <Link
                          to="/whatsapp-campaign"
                          style={{ textDecoration: "none" }}
                        >
                          <TreeItem
                            className="marginLeft5"
                            nodeId="whatsapp"
                            label="Whatsapp Campaign"
                          />
                        </Link>}
                      {presentResourceNames.includes("Email Campaign") && <Link
                        to="/email-campaign"
                        style={{ textDecoration: "none" }}
                      >
                        <TreeItem
                          className="marginLeft5"
                          nodeId="email"
                          label="Email Campaign"
                        />
                      </Link>}
                      {/* <Link to="/sms-campaign" style={{ textDecoration: "none" }}>
                      <TreeItem
                        className="marginLeft5"
                        nodeId="sms"
                        label=" SMS Campaign"
                      />
                    </Link> */}
                      {presentResourceNames.includes("Notifcation Campaign") && <Link
                        to="/notifications"
                        style={{ textDecoration: "none" }}
                      >
                        <TreeItem
                          className="marginLeft5"
                          nodeId="notifications"
                          label=" Notifications"
                        />
                      </Link>}
                    </TreeItem>
                  </TreeView>
                )}

                {isSeoOptionAuthorized && (
                  <Link to="/seo" style={{ textDecoration: "none" }}>
                    <li
                      className={
                        !presentResourceNames.includes("SEO")
                          ? "unauthorized"
                          : ""
                      }
                    >
                      <ScreenSearchDesktopIcon className="icon" />
                      <span>SEO</span>
                    </li>
                  </Link>
                )}
              </Box>
            )}
            {isAnyUsersOptionAuthorized && <p className="title">Users</p>}
            <Box className="nav-sub-sections">
              <Link to="/candidate" style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("Candidate")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <BoyTwoToneIcon className="icon" />
                  <span>Candidate</span>
                </li>
              </Link>

              {presentResourceNames.includes("Employer") && (
                <TreeView
                  aria-label="file system navigator"
                  sx={{
                    flexGrow: 1,
                    maxWidth: 400,
                    overflowY: "auto",
                  }}
                  // defaultCollapseIcon={<ExpandMoreIcon />}
                  // defaultExpandIcon={<ChevronRightIcon />}
                  className="innerTreView"
                >
                  <EmojiPeopleTwoToneIcon
                    className="icon"
                    style={{ marginTop: "10px", fontSize: "1.8rem" }}
                  />
                  <TreeItem
                    className="treeViewNewBlock"
                    nodeId="1"
                    label="Employer"
                  >
                    <Link to="/employer" style={{ textDecoration: "none" }}>
                      <TreeItem
                        className="marginLeft5"
                        nodeId="employer"
                        label="Employer"
                      />
                    </Link>
                    {presentResourceNames.includes("Recruiter") &&
                      <Link to="/recruiter" style={{ textDecoration: "none" }}>
                        <TreeItem
                          className="marginLeft5"
                          nodeId="recruiter"
                          label="Recruiter"
                        />
                      </Link>}
                  </TreeItem>
                </TreeView>
              )}

              <Link to="/jobsahayak" style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("Job Sahayak")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <HailTwoToneIcon className="icon" />
                  <span>JobSahayak</span>
                </li>
              </Link>

              <Link to="/partner" style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("Partner")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <Person3TwoToneIcon className="icon" />
                  <span>Partner</span>
                </li>
              </Link>
            </Box>
            {isAnyRolesOptionAuthorized && (
              <p className="title">Roles and Permission</p>
            )}
            <Box className="nav-sub-sections">
              <Link to="/config-roles" style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("Config Roles")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <PermDataSettingIcon className="icon" />
                  <span>Config Roles</span>
                </li>
              </Link>
              <Link to="/manage-roles" style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("Manage Roles")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <ManageAccountsIcon className="icon" />
                  <span>Manage Roles</span>
                </li>
              </Link>
            </Box>

            {/* {Transactions} */}
            {isTransactionsAuthorized && <p className="title">Payment</p>}
            <Box className="nav-sub-sections ">
              <Link to="/transactions" style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("Payment Details")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <CurrencyRupeeIcon className="icon" />
                  <span>Transactions</span>
                </li>
              </Link>
            </Box>

            {/*JobSeva Announcement */}
            {isAnyJobSevaOptionAuthorized && <p className="title">JobSeva</p>}
            <Box className="nav-sub-sections">
              <Link style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("Jobseva")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <LocalLibraryTwoToneIcon className="icon disabled" />
                  <span className="disabled">Jobs</span>
                </li>
              </Link>

              <li
                style={{ display: "flex", flexDirection: "column" }}
                className={
                  !presentResourceNames.includes("Jobseva")
                    ? "unauthorized"
                    : ""
                }
              >
                <Box
                  // onClick={() => {
                  //   setShowTaskOptions(!showTaskOptions);
                  // }}
                  sx={{ display: "flex", width: "100%", alignItems: "center" }}
                >
                  <Box>
                    <AssignmentIcon className="icon disabled" />
                  </Box>
                  <span
                    style={{ display: "inline-block" }}
                    className="disabled"
                  >
                    JWP Task
                  </span>
                </Box>

                {showTaskOptions && (
                  <Grid container>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9}>
                      <Link to="/jwp-task" style={{ textDecoration: "none" }}>
                        <Box display="flex" alignItems="center">
                          <FiberManualRecordIcon
                            sx={{ fontSize: "14px", color: "#35035d" }}
                          />
                          <Typography
                            fontSize="16px"
                            sx={{ width: "100%", color: "#000" }}
                            textAlign="left"
                          >
                            Travel Task
                          </Typography>
                        </Box>
                      </Link>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9}>
                      <Link
                        to="/jwp-task/deploy"
                        style={{ textDecoration: "none" }}
                      >
                        <Box marginTop={0.2} display="flex" alignItems="center">
                          <FiberManualRecordIcon
                            sx={{ fontSize: "14px", color: "#35035d" }}
                          />
                          <Typography
                            sx={{ color: "#000" }}
                            fontSize="16px"
                            textAlign="left"
                          >
                            Deploy Task
                          </Typography>
                        </Box>
                      </Link>
                    </Grid>
                  </Grid>
                )}
              </li>

              <Link style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("Jobseva")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <CampaignIcon className="icon disabled" />
                  <span className="disabled">Announcement</span>
                </li>
              </Link>

              <Link style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("Jobseva")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <HandshakeIcon className="icon disabled" />
                  <span className="disabled">JWP</span>
                </li>
              </Link>

              <Link style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("Jobseva")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <AddBusinessIcon className="icon disabled" />
                  <span className="disabled">JSK</span>
                </li>
              </Link>
            </Box>
            {isQueriesAuthorized && <p className="title">Support</p>}{" "}
            <Box className="nav-sub-sections">
              <Link to="/contact-us-queries" style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("Contact Queries")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <AlternateEmailIcon className="icon" />
                  <span>Contact Us</span>
                </li>
              </Link>
            </Box>
            {/* Chats Section */}
            {isQueriesAuthorized && <p className="title">Rasa</p>}

            <Box className="nav-sub-sections">
              {/* <Link to="/chats" style={{ textDecoration: "none" }}> */}
              <Link style={{ textDecoration: "none" }}>
                <li
                  className={
                    !presentResourceNames.includes("Contact Queries")
                      ? "unauthorized"
                      : ""
                  }
                >
                  <ChatIcon className="icon disabled" />
                  <span className="disabled">Chats</span>
                </li>
              </Link>
            </Box>
          </ul>
        </div>

        {/* <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div> */}
      </>
    );
  };

  const JobShayakSideBar = () => {
    return (
      <>
        <div className="top">
          <Link to="#" style={{ textDecoration: "none" }}>
            <span className="logo">JobShayak Dashboard </span>
          </Link>
        </div>
        <div className="center">
          <ul>
            <Link
              to="/candidate-onboardform"
              style={{ textDecoration: "none" }}
            >
              <li>
                <DashboardIcon className="icon" />
                <span>Candidate OnBoard Form</span>
              </li>
            </Link>
          </ul>
        </div>
      </>
    );
  };
  return (
    <div className="sidebar">
      {userType === "admin" && <AdminSideBar />}
      {userType === "jobsyahan_shayak" && <JobShayakSideBar />}
    </div>
  );
};

export default Sidebar;
