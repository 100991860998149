import { Box, Button, Snackbar, Alert, MenuItem, FormControl, Select, Container, Grid, Typography, InputLabel, Autocomplete, TextField } from '@mui/material';
import { Formik, Form, Field, useFormikContext } from 'formik';
import "./recruiter.scss";
import { useContext, useEffect, useState, useRef } from 'react';
import { CreateEmployerValidationSchema, CreateRecruiterValidationSchema, UpdateEmployerValidationSchema } from '../../utils/Validations';
import { patchRequestWithToken, postRequest, getRequestWithToken, getRequest } from '../../utils/ApiRequests';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import FocusError from '../../components/FocusError/FocusError';
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import BackBtn from '../../components/backButton/BackBtn';
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";
import { useDropzone } from "react-dropzone";
import NorthIcon from '@mui/icons-material/North';
import CircularProgress from '@mui/material/CircularProgress';
import { uploadImage } from '../../utils/ApiRequests';


const CreateRecruiter = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { handleSnackBar } = useContext(GlobalContext);
  const location = useLocation();

  const [dataById, setDataById] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);
  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);

  const [fileImg, setFileImg] = useState(null);
  const [imageUploader, setImageUploader] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [sector, setSector] = useState([]);
  const [uploadImage, setUploadImage] = useState([]);
  const [sectorObject, setSectorObject] = useState({});

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    phone: "",
    usertype: "",
    companyName: "",
    fullAddress: "",
    city: "",
    state: "",
    pincode: "",
    image: "",
    email: "",
    companyEmail: "",
    businessRoles: "",
    sectorType: "",
    businessCategory: "",
    documentType: "",
    documentId: "",
    recruitOnBehalfOfClients: true,
    recruitmentAgency: "",
  })
  const [edit, setEdit] = useState(false);

  // console.log(location, "this is location");
  const inputRefs = {};

  const scrollToErrorField = (fieldName) => {
    if (inputRefs[fieldName]) {
      inputRefs[fieldName].scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollTargetRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    setFormSubmitted(true);
    let sectorFind = sector.find((sector) => sector.sectorNameEn === "HR, Management & Other Professional Services");
    let formData = new FormData();
    if (edit) {
      formData.append("userid", location?.state?._id);
      formData.append("usertype", "employer");
      formData.append("firstName", values.firstName);
      formData.append("middleName", values.middleName);
      formData.append("lastName", values.lastName);
      formData.append("phone", values.phone);
      formData.append("companyName", values.companyName);
      formData.append("fullAddress", values.fullAddress);
      formData.append("city", values.city);
      formData.append("state", values.state);
      formData.append("pincode", values.pincode);
      formData.append("businessRoles", values.businessRoles === 'Employee' ? 2 : 1);
      formData.append("email", values.email);
      formData.append("companyEmail", values.companyEmail);
      formData.append("image", uploadImage);
      formData.append("sectorType", sectorFind._id);
      formData.append("businessCategory", values.businessCategory === "Independent" ? "1" : "2");
      formData.append("documentType", values.documentType ? values.documentType : 0);
      formData.append("documentId", values.documentId === undefined ? "" : values.documentId);
      formData.append("recruitOnBehalfOfClients", true);
      formData.append("recruitmentAgency", values.recruitmentAgency === "Yes" ? true : false);
    }
    else {
      formData.append("usertype", "employer");
      formData.append("firstName", values.firstName);
      formData.append("middleName", values.middleName);
      formData.append("lastName", values.lastName);
      formData.append("phone", values.phone);
      formData.append("companyName", values.companyName);
      formData.append("fullAddress", values.fullAddress);
      formData.append("city", values.city);
      formData.append("state", values.state);
      formData.append("pincode", values.pincode);
      formData.append("businessRoles", values.businessRoles === 'Employee' ? 2 : 1);
      formData.append("email", values.email);
      formData.append("companyEmail", values.companyEmail);
      formData.append("image", uploadImage);
      formData.append("sectorType", sectorFind._id);
      formData.append("businessCategory", values.businessCategory === "Independent" ? "1" : "2");
      formData.append("documentType", values.documentType ? values.documentType : 0);
      formData.append("documentId", values.documentId === undefined ? "" : values.documentId);
      formData.append("recruitOnBehalfOfClients", true);
      formData.append("recruitmentAgency", values.recruitmentAgency === "Yes" ? true : false);
    }

    try {
      let res = edit ?
        await patchRequestWithToken('admin', formData) : await postRequest('admin', formData);
      if (res.data.status === 'Success') {
        setFormSubmitted(false);
        if (!edit) {
          resetForm();
          if (scrollTargetRef.current) {
            scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
          }
          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate('/recruiter');
          }, 1000);
        }
        else {
          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate('/recruiter');
          }, 1000);
        }

      }
      else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error")
      }
    } catch (err) {
      console.log(err);
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error")
    }
  }

  const fetchPincodeDetails = async (pincode, setFieldValue) => {
    try {
      let response = await getRequest(`pincode/${pincode}`);
      const data = response.data.data;
      setFieldValue('district', data.Districtname);
      setFieldValue('state', data.statename);
      setFieldValue('city', data.circlename);
    } catch (err) {
      console.log(err)
    }
  }


  const getEmployerById = async () => {
    try {
      let response = await getRequestWithToken(`admin/userbyid/?usertype=employer&userid=${location.state?._id}`);
      setDataById(response.data.data)

      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setShowLoader(false);
    }
  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Recruiter";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    // console.log("hello")
    const checkAccesInArray = localStorage.getItem("resourceNames");
    // console.log("resourceNames : " + checkAccesInArray);
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Recruiter"))
  }

  const getSectors = async () => {
    try {
      let data = await getRequestWithToken("admin/sectors");
      if (data.data.status === "Success") {
        setSector([...data.data.data]);
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.msg, "error");
    }
  };

  useEffect(() => {
    getSectors();
  }, [])


  useEffect(() => {

    checkPermissionFunc();
    againCheckPermissions();

    if (location.state?._id) {
      getEmployerById()
    }
    else {
      setShowLoader(false);
    }


    if (location?.state?._id) {
      const { employer = {} } = dataById;
      const foundObject = sector.find(obj => obj._id === dataById?.sectorType);
      setFileImg(dataById?.businessLogo);
      setSectorObject(foundObject);
      const {
        usertype = "employer",
        companyName = dataById?.companyName,
        firstName = dataById?.firstName,
        middleName = dataById?.middleName ? dataById?.middleName : "",
        lastName = dataById?.lastName,
        phone = dataById?.phone,
        companyEmail = dataById?.companyEmail,
        fullAddress = dataById?.companyAddress?.fullAddress,
        city = dataById?.companyAddress?.city,
        state = dataById?.companyAddress?.state,
        pincode = dataById?.companyAddress?.pincode,
        image = dataById?.businessLogo,
        email = dataById?.email,
        businessRoles = dataById?.businessRoles === 1 ? "Business Owner" : "Employee",
        sectorType = foundObject,
        businessCategory = dataById?.businessCategory === 1 ? "Independent" : "Registered",
        documentType = dataById?.kycDetails?.documentType === 0 ? "" : dataById?.kycDetails?.documentType,
        documentId = dataById?.kycDetails?.documentId ? dataById?.kycDetails?.documentId : "",
        recruitOnBehalfOfClients = dataById?.recruitOnBehalfOfClients === false ? "Employer" : "Recruiter",
        recruitmentAgency = dataById?.recruitmentAgency === false ? "No" : "Yes",
      } = employer;

      setInitialValues({
        usertype,
        firstName,
        lastName,
        middleName,
        phone,
        companyEmail,
        companyName,
        fullAddress,
        city,
        state,
        pincode,
        image,
        email,
        businessRoles,
        sectorType,
        businessCategory,
        documentType,
        documentId,
        recruitOnBehalfOfClients,
        recruitmentAgency
      });
      setEdit(true);
    } else {
      setInitialValues({
        usertype: "",
        firstName: "",
        lastName: "",
        middleName: "",
        phone: "",
        companyEmail: "",
        companyName: "",
        fullAddress: "",
        city: "",
        state: "",
        pincode: "",
        image: "",
        email: "",
        businessRoles: "",
        sectorType: "",
        businessCategory: "",
        // recruitOnBehalfOfClients,
        documentType: "",
        documentId: "",
        recruitmentAgency: ""
      });
      setEdit(false);
    }
  }, [location.state, dataById._id, isIncludesValue, sector])

  const onDrop = (acceptedFiles) => {
    setFileData(acceptedFiles);
    acceptedFiles.forEach((file, index) => {
      let url = URL.createObjectURL(file);
      if (index === 0) setFileImg(url);
    });

    setUploadImage(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop
  });

  return (
    (isIncludesValue) ? <>
      {showLoader ? <ReusableLoader /> : <Box className="create-employer-section" ref={scrollTargetRef}>

        <Container>
          <Box className="create-employer">
            <Box className="headingPlusBackBtn">
              <BackBtn />
              <h1 className='formHeadings'>{edit ? "Update Recruiterr" : "Create Recruiter"}</h1>
            </Box>
            <Box className="create-employer-form-wrapper">
              <Formik
                initialValues={initialValues}
                validationSchema={CreateRecruiterValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ errors, values, setFieldValue, resetForm }) => (

                  <Form className="create-employer-form ">

                    <Box className="backgroundStyle">
                      <Grid container spacing={2} className='cstmFormGridEmployer'>

                        <Grid item xs={12} sm={8} md={6}>
                          <Box className="form-control" >
                            <label className="form-label" htmlFor="businessCategory">Choose Business Category<span className='must-fill-fields'>*</span></label>
                            <div role="group" aria-labelledby="radio-group">
                              <Grid container spacing={2} >
                                {['Registered', 'Independent'].map((item, index) => (
                                  <Grid item xs={12} sm={6} md={6} key={index}>
                                    <Box className="form-control">
                                      <label key={index} className='form-label months_label' htmlFor={item}>
                                        <Field type="radio" id={item} name="businessCategory" value={item} />
                                        {item}
                                      </label>
                                    </Box>
                                  </Grid>

                                ))}
                              </Grid>
                            </div>
                            {errors.businessCategory && <Box className="form-error">{errors.businessCategory}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={8} md={6}>
                          <Box className="form-control" >
                            <label className="form-label" htmlFor="recruitOnBehalfOfClients">Dashboard Type<span className='must-fill-fields'>*</span></label>
                            <div role="group" aria-labelledby="radio-group">
                              <Grid container spacing={2} >
                                {['Recruiter', 'Employer'].map((item, index) => (
                                  <Grid item xs={12} sm={6} md={6} key={index}>
                                    <Box className="form-control">
                                      <label key={index} className='form-label months_label' htmlFor={item}>
                                        <Field type="radio" id={item} name="recruitOnBehalfOfClients" value={item} checked={item === 'Recruiter'} />
                                        {item}
                                      </label>
                                    </Box>
                                  </Grid>
                                ))}
                              </Grid>
                            </div>
                            {errors.recruitOnBehalfOfClients && <Box className="form-error">{errors.recruitOnBehalfOfClients}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={8} md={6}>
                          <Box className="form-control" >
                            <label className="form-label" htmlFor="recruitmentAgency">Is Recruitment Agency?<span className='must-fill-fields'>*</span></label>
                            <div role="group" aria-labelledby="radio-group">
                              <Grid container spacing={2} >
                                {['Yes', 'No'].map((item, index) => (
                                  <Grid item xs={12} sm={6} md={6} key={index}>
                                    <Box className="form-control">
                                      <label key={index} className='form-label months_label' htmlFor={item}>
                                        <Field type="radio" id={item} name="recruitmentAgency" value={item} />
                                        {item}
                                      </label>
                                    </Box>
                                  </Grid>

                                ))}
                              </Grid>
                            </div>
                            {errors.recruitmentAgency && <Box className="form-error">{errors.recruitmentAgency}</Box>}
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className='cstmFormGridEmployer'>
                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control" >
                            <label className="form-label" htmlFor="firstName"> First Name <span className='must-fill-fields'>*</span></label>
                            <Field id="firstName"
                              type="text" placeholder="Enter First Name"
                              name="firstName"
                              className="form-input" size="small"
                            />
                            {errors.firstName && <Box className="form-error">{errors.firstName}</Box>}

                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="middleName"> Middle Name</label>
                            <Field id="middleName"
                              type="text" placeholder="Enter Middle Name"
                              name="middleName"
                              className="form-input" />
                            {errors.middleName && <Box className="form-error">{errors.middleName}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} >
                          <Box className="form-control">
                            <label className="form-label" htmlFor="lastName">Last Name <span className='must-fill-fields'>*</span></label>
                            <Field id="lastName"
                              type="text" placeholder="Enter Last Name"
                              name="lastName"
                              className="form-input" />
                            {errors.lastName && <Box className="form-error">{errors.lastName}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} >
                          <Box className="form-control">
                            <label className="form-label" htmlFor="phone">Mobile Number<span className='must-fill-fields'>*</span></label>
                            <Field id="phone"
                              type="text" placeholder="Enter Mobile Number"
                              name="phone"
                              className="form-input"
                              maxLength={10}
                            />
                            {errors.phone && <Box className="form-error">{errors.phone}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="email">Email <span className='must-fill-fields'>*</span></label>
                            <Field
                              id="email"
                              type="text" placeholder="Enter Email"
                              name="email"
                              className="form-input" />
                            {errors.email && <Box className="form-error">{errors.email}</Box>}
                          </Box>
                        </Grid>

                      </Grid>



                      <Grid container spacing={2} className='cstmFormGridEmployer'>



                        <Grid item xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
                          <Typography style={{ fontWeight: "700" }}>Company Address</Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="companyName"> Company Name  <span className='must-fill-fields'>*</span></label>
                            <Field innerRef={(el) => { inputRefs.companyName = el }} id="companyName"
                              type="text" placeholder="Enter Company Name"
                              name="companyName"
                              className="form-input" />
                            {errors.companyName && <Box className="form-error">{errors.companyName}</Box>}
                          </Box>

                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="companyEmail"> Company Email <span className='must-fill-fields'>*</span></label>
                            <Field
                              id="companyEmail"
                              type="text" placeholder="Enter Company Email"
                              name="companyEmail"
                              className="form-input" />
                            {errors.companyEmail && <Box className="form-error">{errors.companyEmail}</Box>}
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className='cstmFormGridEmployer'>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="fullAddress">Address <span className='must-fill-fields'>*</span></label>
                            <Field
                              innerRef={(el) => { inputRefs.fullAddress = el }}

                              id="fullAddress"

                              type="text" placeholder="Enter Adress"
                              name="fullAddress"
                              className="form-input" />
                            {errors.fullAddress && <Box className="form-error">{errors.fullAddress}</Box>}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="pincode"> Pincode <span className='must-fill-fields'>*</span></label>
                            <Field id="pincode"
                              type="text" placeholder="Enter pincode"
                              name="pincode"
                              className="form-input"
                              onBlur={(e) => fetchPincodeDetails(e.target.value, setFieldValue)}
                            // onChange={getPincode}
                            />
                            {errors.pincode && <Box className="form-error">{errors.pincode}</Box>}
                          </Box>
                        </Grid>


                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="city">City <span className='must-fill-fields'>*</span></label>
                            <Field id="city"
                              type="text" placeholder="Enter city"
                              name="city"
                              className="form-input" />
                            {errors.city && <Box className="form-error">{errors.city}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="state">State <span className='must-fill-fields'>*</span></label>
                            <Field disabled={edit} id="state"
                              type="text" placeholder="Enter state"
                              name="state"
                              className="form-input" />
                            {errors.state && <Box className="form-error">{errors.state}</Box>}
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className='cstmFormGridEmployer'>

                        <Grid item xs={12} sm={12} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="sectorType">
                              Company Sector<span className="must-fill-fields">*</span>
                            </label>
                            <TextField
                              disabled
                              size='small'
                              value='HR, Management & Other Professional Services' />
                            {/* <Autocomplete
                              id="tags-outlined"
                              options={sector || []}
                              getOptionLabel={(option) => option.sectorNameEn}
                              filterSelectedOptions
                              value={sector.find((option) => option._id === values.sectorType?._id) || null}
                              onChange={(event, newValue) => {
                                setFieldValue('sectorType', newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} placeholder="Select Sector" />
                              )}
                            /> */}
                            {errors.sectorType && (
                              <Box className="form-error">{errors.sectorType}</Box>
                            )}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={8} md={6}>
                          <Box className="form-control" >
                            <label className="form-label" htmlFor="businessRoles">Your Designation<span className='must-fill-fields'>*</span></label>
                            <div role="group" aria-labelledby="radio-group">
                              <Grid container spacing={2} >
                                {['Employee', 'Business Owner'].map((item, index) => (
                                  <Grid item xs={12} sm={6} md={6} key={index}>
                                    <Box className="form-control">
                                      <label key={index} className='form-label months_label' htmlFor={item}>
                                        <Field type="radio" id={item} name="businessRoles" value={item} />
                                        {item}
                                      </label>
                                    </Box>
                                  </Grid>

                                ))}
                              </Grid>
                            </div>
                            {errors.businessRoles && <Box className="form-error">{errors.businessRoles}</Box>}
                          </Box>
                        </Grid>

                      </Grid>

                      <Grid container spacing={2} className='cstmFormGridEmployer'>

                        <Grid item xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
                          <Typography style={{ fontWeight: "700" }}>KYC Details</Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="documentType">Document Type </label>
                            <FormControl name="documentType" fullWidth size="small">
                              {values.documentType === "" && <InputLabel shrink={false}>Select Document Type </InputLabel>}
                              <Select
                                displayEmpty
                                name="documentType"
                                id="documentType"
                                value={values.documentType}
                                onChange={(event) => {
                                  const selectedDocumentTypeValue = event.target.value;
                                  setFieldValue("documentType", selectedDocumentTypeValue);
                                }}
                              >

                                <MenuItem value="1" >GST</MenuItem>
                                <MenuItem value="2">CIN</MenuItem>
                                <MenuItem value="4">Udhyam Aadhar</MenuItem>
                                <MenuItem value="3">Business PAN</MenuItem>
                              </Select>
                            </FormControl>
                            {errors.documentType && <Box className="form-error">{errors.documentType}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="documentId">Document ID</label>
                            <Field id="documentId"
                              disabled={values.documentType === "" ? true : false}
                              type="text" placeholder="Document ID"
                              name="documentId"
                              className="form-input" />
                            {errors.documentId && <Box className="form-error">{errors.documentId}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <Box className="mt-5 px-4 py-3">

                            <label className="form-label" htmlFor="image"> Upload your Business Logo</label>

                            <Grid justifyContent="center" className='' style={{ border: "1px solid #e4e3e3", borderRadius: "5px", backgroundColor: "white" }} item xs={12}>
                              <Box display="flex" className="cursor-pointer items-center flex-col" style={{ position: "relative" }} justifyContent="center" width="100%">
                                {<Box {...getRootProps({ className: 'dropzone' })} className="" style={{ textAlign: "center", minHeight: "180px", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                  {fileImg !== null && <img style={{ height: "100%", width: "100%", display: "block", objectFit: "cover", maxHeight: "180px" }} src={fileImg} alt="selected png, svg" />}

                                  {fileImg === null && <>
                                    <input {...getInputProps()} />
                                    <NorthIcon color="text-secondary-black" fontSize="small" />
                                    <Typography className="text-secondary-black text-xs md:text-base font-medium ">
                                      Upload Here
                                    </Typography>
                                    <Typography className="text-secondary-black text-xs md:text-base font-medium ">
                                      File types supported: jpg, jpeg, png
                                    </Typography>
                                    <Typography className="text-secondary-black text-xs md:text-base font-medium ">
                                      Maximum file size: 5 MB
                                    </Typography></>}
                                </Box>}

                                {imageUploader && <Box className="loaderClass" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '300px' }} >
                                  <CircularProgress />
                                </Box>}
                              </Box>

                              {errors.businessLogo && <Typography fontSize="14px" color="#d32f2f" marginTop={2} textAlign="center">{errors.businessLogo.message}</Typography>}
                            </Grid>
                            <Typography onClick={() => { setFileImg(null); setUploadImage([]) }} style={{ color: "#ff671f", cursor: "pointer" }}>Change</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box className="form-button-section">
                      <Link to='/employer'>
                        <Button className="form-button"
                          variant="contained"
                          onClick={() => {
                            resetForm();
                          }}
                          color="error"
                        >Cancel</Button>
                      </Link>

                      <Button className="form-button"
                        variant="contained"
                        type="submit"
                        color="success"
                        disabled={formSubmitted}
                      >
                        {formSubmitted ? <CircularProgress size={24} /> :
                          edit ? "Update" : "Create"}
                      </Button>

                    </Box>
                    <FocusError handleSnackBar={handleSnackBar} />
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Container>

      </Box >}
    </> : (<Unauthorized />)

  )

}
export default CreateRecruiter;