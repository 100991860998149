import React, { useState, useEffect } from "react";
import { Grid, CircularProgress } from "@mui/material";
import { getRequestWithToken } from "../../utils/ApiRequests";
import PieChartComponent from "../../components/barChart/PieChartComponent";
import BarChartComponent from "../../components/barChart/BarChartComponent";
import TableStatsComponent from "../../components/barChart/TableStatsComponent";
const EmployerStats = () => {
  const [loadingStats,setLoadingStats] = useState(true);
  const [employerRegistrationData, setEmployerRegistrationData] =
  useState([]);
  const [locationWiseEmployerStats,setLocationWiseEmployerStats] = useState([]);
  const [sectorWiseEmployerStats,setSectorWiseEmployerStats] = useState([]);
  const [userTypeData, setUserTypeData] = useState([]);
  const [paidUserData, setPaidUserData] = useState([]);

  const columns = [
    {
      accessorKey: "state",
      header: "State",
      accessorFn: (row) => (
        <span style={{ textTransform: "capitalize" }}>{row.state}</span>
      ),
    },
    { accessorKey: "count", header: "Active Users" },
   
  ];

  const columns2 = [
    {
      accessorKey: "sectoreNameEn",
      header: "Sector Name",
    },
    { accessorKey: "count", header: "Active Users" },
   
  ];

  const getEmployerAllStats = async () => {
    try {
      let response = await Promise.allSettled([
        getRequestWithToken(`admin/employer/registration`),
        getRequestWithToken(`admin/employer/dashboard-type`), 
        getRequestWithToken(`admin/employer/state`),
        getRequestWithToken(`admin/employer/sector-wise`),
        getRequestWithToken(`admin/employer/payment-details`)
      ]);
      if (response[0].status === "fulfilled") {
        let employerData = response[0]?.value?.data?.data;
        const transformedData = [
          {
            label: "Mobile Verified",
            count: employerData.totalMobileOtpVerified || 0,
          },
          {
            label: "Email Verified",
            count: employerData.totalEmailVerified || 0,
          },
          {
            label: "Registration Complete",
            count: employerData.totalProfileComplete || 0,
          },
          {
            label: "Active User",
            count: employerData.totalActiveEmployers || 0,
          },
          {
            label: "User with Active Job",
            count: employerData.totalProfileCompleteWithJobPosted || 0,
          },
        ];
        setEmployerRegistrationData(transformedData);
      }
      if (response[1].status === "fulfilled") {
        let DataValues = response[1]?.value?.data?.data;
        const userData = [
          { name: "Recruiter", value: DataValues.recruiterCount || 0 },
          { name: "Employer", value: DataValues.employerCount || 0 },
        ];
        setUserTypeData(userData);
      }
      if (response[2].status === "fulfilled") {
        let locationBasedJobStats = response[2]?.value?.data?.data;
        setLocationWiseEmployerStats(locationBasedJobStats);
        setSectorWiseEmployerStats(locationBasedJobStats);
      }
      if (response[3].status === "fulfilled") {
        let sectorWiseBasedJobStats = response[3]?.value?.data?.data;
        setSectorWiseEmployerStats(sectorWiseBasedJobStats);
      }
      if (response[4].status === "fulfilled") {
        let DataValues = response[4]?.value?.data?.data;
        const paidUsersTansformedData = [
          { label: "Total Paid User", count: DataValues.totalCount || 0 },
          { label: "Udbhav User", count: DataValues.UdbhavCount || 0 },
          { label: "Uday User", count: DataValues.UdayCount || 0 },
          { label: "Udyam User", count: DataValues.UdyamCount || 0 },
          { label: "Additional HC User", count: DataValues.TrialCount || 0 },
        ];
        setPaidUserData(paidUsersTansformedData);
        
      }
      
    } catch (error) {
      setEmployerRegistrationData([]);
      setUserTypeData([]);
      setLocationWiseEmployerStats([]);
      setSectorWiseEmployerStats([]);
      setPaidUserData([]);
    }
    finally{
        setLoadingStats(false);
    }
  };

  useEffect(() => {
    getEmployerAllStats();
  }, []);

  if (loadingStats) {
      return (
        <div
          style={{
            width: "100%",
            height: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <CircularProgress />
            <p> Loading Statistics ....</p>
          </div>
        </div>
      );
    }

  return (
    <div className="employercharts" style={{padding:"0px 20px 20px 20px"}}>
      <h3>Employer Statistics</h3>

      <Grid container spacing={2} className="cstmFormGridEmployer">
        <Grid item xs={12} sm={6} md={6}>
          <BarChartComponent
            data={employerRegistrationData}
            title="REGISTRATION COUNT"
            aspect={3 / 1}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <PieChartComponent title="USER TYPE BASED ON THE DASHBOARD" data={userTypeData} />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <TableStatsComponent
            data={locationWiseEmployerStats}
            columns={columns}
            title="Location Wise"/>
        </Grid>

         <Grid item xs={12} sm={6} md={6}>
          <TableStatsComponent
            data={sectorWiseEmployerStats}
            columns={columns2}
            title="Sector Wise"/>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <BarChartComponent
            data={paidUserData}
            title="PAID USERS"
            aspect={3 / 1}
            barType="vertical"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default EmployerStats;
