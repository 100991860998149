import "./transaction.scss";
import * as React from "react";
import { Box, Chip, Typography, CircularProgress, FormControl, InputLabel, Select, MenuItem, TextField, Paper, Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getRequestWithToken } from "../../utils/ApiRequests";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
// import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';

import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const Transactions = () => {
  const [transactionData, setTransactionData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(null);

  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchBarValue, setSearchBarValue] = useState("");

  const { handleSnackBar } = useContext(GlobalContext);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });



  const [searchField, setSearchField] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [activeFilters, setActiveFilters] = useState([]);

  const handleFieldChange = (event) => {
    setSearchField(event.target.value);
  };

  const handleKeywordChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const addFilter = () => {
    if (searchField && searchKeyword) {
      setActiveFilters([...activeFilters, { field: searchField, keyword: searchKeyword }]);
      setSearchField("");
      setSearchKeyword("");
    }
  };

  const removeFilter = (index) => {
    setActiveFilters(activeFilters.filter((_, i) => i !== index));
  };

  const columns = [
    {
      accessorKey: "id",
      header: "S.No.",
      size: 120,
      enableColumnFilter: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        const rowIndex = row.index;
        return <span>{rowIndex + 1 + pageIndex * pageSize}</span>;
      },
    },
    {
      accessorKey: "readableEmployerId",
      header: "User ID",
      size: 250,
      id: "employerId"
    },
    // {
    //   accessorFn: (row) => row.employer?.company_name ?? "--",
    //   header: "Transaction Type",
    //   size: 300,
    //   id: "transactionType",
    // },
    {
      accessorFn: (row) => row?.merchantTransactionId ?? "--",
      header: "Transaction Number",
      size: 500,
      id: "transactionNumber",
    },
    {
      accessorFn: (row) => row?.paymentMode ?? "--",
      header: "Payment Mode",
      size: 300,
      enableColumnFilter: false,
    },

    {
      accessorKey: "withGST",
      header: "Transaction Amount",
      size: 250,
      enableColumnFilter: false,
    },

    {
      accessorKey: "paymentInitiationDate",
      header: "Date & Time",
      size: 250,
      enableColumnFilter: false,
      Cell: ({ cell }) => (
        <span>
          {new Date(cell.getValue()).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })}
        </span>
      ),
    },

    {
      accessorKey: "jobs",
      header: "Payment Status",
      size: 200,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <div style={{ textTransform: "uppercase" }}>{row?.original?.state}</div>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (!transactionData.length) {
        setLoadingDataInGrid(true);
      } else {
        setIsRefetching(true);
      }

      try {
        const filterParams = {
          // global: globalFilter,
          ...Object.fromEntries(
            columnFilters.map((filter) => [filter.id, filter.value])
          ),
        };

        const filterQueryString = new URLSearchParams(filterParams).toString();

        const response = await getRequestWithToken(
          `admin/payment/transaction/history?page=${pagination.pageIndex + 1
          }&limit=${pagination.pageSize}&${filterQueryString}`
        );

        setTransactionData(response?.data?.data);
        setRowCount(response?.data?.totalCount);
        setIsError(false);
        setLoadingDataInGrid(false);
        setShowLoader(false);
      } catch (error) {
        setIsError(true);
        handleSnackBar(
          true,
          error?.response?.data?.message || error?.message,
          "error"
        );
        setLoadingDataInGrid(false);
      }
      setLoadingDataInGrid(false);
      setIsRefetching(false);
      setShowLoader(false);
    };
    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Payment Details";
    // console.log(JSON.parse(checkPermission(resourceToUpdate)));
    const permissions = checkPermission(resourceToUpdate);
    // setCanCreate(permissions.canCreate);
    // setCanDelete(permissions.canDelete);
    // setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Payment Details"));
  };

  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();
  }, [pageSize, pageNumber, isIncludesValue, searchBarValue]);

  const table = useMaterialReactTable({
    columns,
    data: transactionData,
    initialState: { showColumnFilters: false },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableGlobalFilter: false,
    enableSorting: false,
    muiLinearProgressProps: {
      style: {
        backgroundColor: "secondary",
      },
    },

    // muiSkeletonProps: {
    //   animation: "wave",
    // },
    // muiLinearProgressProps: {
    //   style: {
    //     backgroundColor: "#fff", // Custom orange color
    //   },
    // },
    // muiCircularProgressProps: {
    //   style: {
    //     color: "secondary",
    //     display: "block",
    //   },
    // },
    // muiToolbarAlertBannerProps: isError
    //   ? {
    //       color: "error",
    //       children: "Error loading data",
    //     }
    //   : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading: loadingDataInGrid,
      pagination,
      // showAlertBanner: isError,
      showSkeletons: isRefetching,
      showProgressBars: isRefetching,
      sorting,
    },
    renderBottomToolbarCustomActions: ({ table }) => {
      const { pageIndex, pageSize } = table.getState().pagination;
      const totalPages = Math.ceil(rowCount / pageSize);
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            Page {pageIndex + 1} of {totalPages}
          </Typography>
        </Box>
      );
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const pinnedColumns = table.getState().columnPinning;
      return (
        <Box>
          <Chip
            label={`Left Pinned: ${pinnedColumns.left.length}`}
            color="primary"
            sx={{ mr: 1 }}
          />
          <Chip
            label={`Right Pinned: ${pinnedColumns.right.length}`}
            color="secondary"
          />
        </Box>
      );
    },
  });

  return isIncludesValue ? (
    <Box className="transactions-section">
      {/* <Typography className="section-title">Transactions</Typography> */}

      {/* <Paper elevation={3} sx={{ padding: 3, borderRadius: 3, mb: 4 }}>
        <Box display="flex" gap={2} alignItems="center">
         
          <FormControl variant="outlined" sx={{ minWidth: 200 }}>
            <InputLabel>Select Field</InputLabel>
            <Select
              value={searchField}
              onChange={handleFieldChange}
              label="Select Field"
              startAdornment={<FilterListIcon sx={{ mr: 1 }} />}
            >
              <MenuItem value="id">ID</MenuItem>
              <MenuItem value="name">Name</MenuItem>
              <MenuItem value="companyName">Company Name</MenuItem>
              <MenuItem value="phone">Phone</MenuItem>
              <MenuItem value="email">Email</MenuItem>
            </Select>
          </FormControl>

          <TextField
            variant="outlined"
            label="Search"
            value={searchKeyword}
            onChange={handleKeywordChange}
            disabled={!searchField}
            sx={{ width: 300 }}
            InputProps={{
              startAdornment: <SearchIcon sx={{ mr: 1, color: "action.active" }} />,
            }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={addFilter}
            disabled={!searchField || !searchKeyword}
            sx={{
              textTransform: "none",
              height: "100%",
              borderRadius: 2,
            }}
          >
            Add Filter
          </Button>
        </Box>

        <Box mt={3} display="flex" gap={1} flexWrap="wrap">
          {activeFilters.map((filter, index) => (
            <Button
              key={index}
              variant="outlined"
              color="secondary"
              onClick={() => removeFilter(index)}
              sx={{
                textTransform: "none",
                borderRadius: 2,
                padding: "6px 12px",
              }}
            >
              {filter.field}: {filter.keyword}
            </Button>
          ))}
        </Box>
      </Paper> */}

      <Box sx={{ width: "100%" }}>
        {showLoader ? (
          <Box
            sx={{ width: "100%", height: "100%" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <MaterialReactTable table={table} />
        )}
      </Box>
    </Box>
  ) : (
    <Unauthorized />
  );
};
export default Transactions;
