import "./urlShortener.scss";
import * as React from "react";
import {
  Box,
  Chip,
  Typography,
  CircularProgress,
  Grid,
  Button,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import { checkPermission } from "../../utils/userPermissions";
import LinkIcon from "@mui/icons-material/Link";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import Unauthorized from "../../components/unauthorized/Unauthorized";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Formik, Form, Field } from "formik";
import { createLinkValidatonSchema } from "../../utils/Validations";
import {
  getRequestForLinkShortener,
  postRequestForLinkShortener,
} from "../../utils/ApiRequests";

const UrlShortener = () => {
  const [data, setData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showGenerateLinkModal, setShowGenerateLinkModal] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");

  const [canRead, setCanRead] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchBarValue, setSearchBarValue] = useState("");

  const { handleSnackBar } = useContext(GlobalContext);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const initialValues = {
    title: "",
    link: "",
  };

  const handleSubmit = async (values,{resetForm}) => {
    const { title, link ,custom_link} = values;
    try {
      setFormSubmitted(true);
      let res = await postRequestForLinkShortener({
        urlTitle: title,
        longUrl: link,
        customShortCode : !custom_link ? null : custom_link.trim().replaceAll(" ","-")
      });
      res = res.shortUrl;
      resetForm();
      setOpenModal(false);
      handleSnackBar(true, "Short Link is generated successfully", "success");
      setShowGenerateLinkModal(true);
      setGeneratedLink(res);
    } catch (error) {
      console.log(error);
    } finally {
      setFormSubmitted(false);
    }
  };

  const fetchData = async () => {
    if (!data.length) {
      setLoadingDataInGrid(true);
    } else {
      setIsRefetching(true);
    }

    try {
      const filterParams = {
        // global: globalFilter,
        ...Object.fromEntries(
          columnFilters.map((filter) => [filter.id, filter.value])
        ),
      };

      const filterQueryString = new URLSearchParams(filterParams).toString();

      const response = await getRequestForLinkShortener(`?list=true&skip=${pagination.pageIndex}&limit=${pagination.pageSize}&${filterQueryString}`);
      setData(response?.urls);
      setRowCount(response?.totalItems);
      setIsError(false);
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      setIsError(true);
      handleSnackBar(
        true,
        error?.response?.data?.message || error?.message,
        "error"
      );
      setLoadingDataInGrid(false);
    }
    setLoadingDataInGrid(false);
    setIsRefetching(false);
    setShowLoader(false);
  };

  const columns = [
    {
      accessorKey: "id",
      header: "S.No.",
      size: 120,
      enableColumnFilter: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        const rowIndex = row.index;
        return <span>{rowIndex + 1 + pageIndex * pageSize}</span>;
      },
    },
    { 
      accessorFn: (row) => {
        return (<span>{row?.urlTitle}</span>)
      },
      accessorKey: "urlTitle",
      header: "Title",
      size: 350,
      id: "urlTitle",
    },
    {
      accessorFn: (row) => {
        let shortLink = row?.shortCode
          ? `https://jyti.in/?u=${row?.shortCode}`
          : "";
        return <span style={{color:"blue"}} onClick={() => copyText(shortLink)}> {shortLink}</span>;
      },
      accessorKey: "shortLink",
      header: "ShortLink",
      size: 350,
      id: "shortCode",
    },

    {
      accessorKey: "createdAt",
      header: "Created At",
      size: 350,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        const createdAt = row.original.createdAt || "--";
        return (
          <span>
            {new Date(createdAt).toLocaleString("en-US", {
              timeZone: "Asia/Kolkata",
            })}
          </span>
        );
      },
    },

    {
      accessorKey: "z_action",
      header: "Action",
      size: 280,
      enableColumnFilter: false,
      Cell: ({ row }) =>
          <Box
            style={{
              display: "flex",
              gap: "0.5rem",
              cursor: "pointer",
              alignItems: "center",
            }}
            onClick={() => {
              copyText(`https://jyti.in/?u=${row?.original?.shortCode}`);
            }}
          >
            <LinkIcon />
            <span> Copy Link</span>
          </Box>
    },
  ];

  useEffect(() => {
   fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Payment Details";
    // console.log(JSON.parse(checkPermission(resourceToUpdate)));
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("URL Shortener"));
  };

  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();
  }, [pageSize, pageNumber, isIncludesValue, searchBarValue]);

  const table = useMaterialReactTable({
    columns,
    data: data || [],
    initialState: { showColumnFilters: false },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableGlobalFilter: false,
    enableSorting: false,
    muiLinearProgressProps: {
      style: {
        backgroundColor: "secondary",
      },
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading: loadingDataInGrid,
      pagination,
      // showAlertBanner: isError,
      showSkeletons: isRefetching,
      showProgressBars: isRefetching,
      sorting,
    },
    renderBottomToolbarCustomActions: ({ table }) => {
      const { pageIndex, pageSize } = table.getState().pagination;
      const totalPages = Math.ceil(rowCount / pageSize);
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            Page {pageIndex + 1} of {totalPages}
          </Typography>
        </Box>
      );
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const pinnedColumns = table.getState().columnPinning;
      return (
        <Box>
          <Chip
            label={`Left Pinned: ${pinnedColumns.left.length}`}
            color="primary"
            sx={{ mr: 1 }}
          />
          <Chip
            label={`Right Pinned: ${pinnedColumns.right.length}`}
            color="secondary"
          />
        </Box>
      );
    },
  });

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseGenerateLinkModal = () => {
    setShowGenerateLinkModal(false);
    fetchData();
  };

  const copyText = (textToCopy) => {
    if(!canRead) {
      handleSnackBar(true, "You don't have permission to copy link", "error");
      return;
    }
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => handleSnackBar(true, "Link is copied", "success"))
      .catch((err) => handleSnackBar(true, err, "error"));
  };

  const handleCopyGeneratedLink = () => {
    copyText(generatedLink);
    handleCloseGenerateLinkModal();
  };

  return isIncludesValue ? (
    <Box className="urlShortenerSection">
      <ModalComponent
        openModal={openModal}
        setOpenModal={setOpenModal}
        modalTitle="Create Link"
        modalWidth="35%"
        handleOnCloseBtnClick={handleCloseModal}
      >
        <Box style={{ padding: "15px" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={createLinkValidatonSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ errors, values, setFieldValue, resetForm }) => (
              <Form className="create-url-link-form">
                <Grid container spacing={2} className="cstmFormGridUrlLink">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-control">
                      <label className="form-label" htmlFor="title">
                        {" "}
                        Title <span className="must-fill-fields">*</span>
                      </label>
                      <Field
                        id="title"
                        type="text"
                        placeholder="Enter Title"
                        name="title"
                        className="form-input"
                        size="small"
                      />
                      {errors.title && (
                        <Box className="form-error">{errors.title}</Box>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-control">
                      <label className="form-label" htmlFor="link">
                        {" "}
                        Link <span className="must-fill-fields">*</span>
                      </label>
                      <Field
                        id="link"
                        type="text"
                        placeholder="Enter Link"
                        name="link"
                        className="form-input"
                        size="small"
                      />
                      {errors.link && (
                        <Box className="form-error">{errors.link}</Box>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="form-control">
                      <label className="form-label" htmlFor="custom_link">
                        Custom Link
                      </label>
                      <Field
                        id="custom_link"
                        type="text"
                        placeholder="Enter Custom Link (eg. custom-link)"
                        name="custom_link"
                        className="form-input"
                        size="small"
                      />
                    </Box>
                  </Grid>

                </Grid>

                <Box className="form-button-section">
                  <Button
                    className="form-button"
                    variant="contained"
                    type="submit"
                    color="success"
                    disabled={formSubmitted}
                  >
                    Generate Link
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </ModalComponent>

      <ModalComponent
        openModal={showGenerateLinkModal}
        setOpenModal={setShowGenerateLinkModal}
        modalTitle="Generated Link"
        modalWidth="35%"
        handleOnCloseBtnClick={handleCloseGenerateLinkModal}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "15px",
            gap:"10px"
          }}
        >
          <Box> Link : <span style={{color:"blue"}}>{generatedLink}</span></Box>
          <Button
            className="form-button"
            variant="contained"
            type="submit"
            color="success"
            onClick={handleCopyGeneratedLink}
          >
            Copy Link
          </Button>
        </Box>
      </ModalComponent>

      <Box className="urlShortenerHeader">
        <h3 className="formHeadings">JY Link Shortener</h3>
        {canCreate && (
          <Button variant="contained" color="success" onClick={handleOpenModal}>
            Create Link
          </Button>
        )}
      </Box>

      <Box sx={{ width: "100%" }}>
        {showLoader ? (
          <Box
            sx={{ width: "100%", height: "100%" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <MaterialReactTable table={table} />
        )}
      </Box>
    </Box>
  ) : (
    <Unauthorized />
  );
};
export default UrlShortener;
