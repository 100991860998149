import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { getRequestWithToken } from "../../utils/ApiRequests";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { Box, Button, Chip, Container, Grid, Typography } from '@mui/material';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import "./employer.scss";
import BackBtn from '../../components/backButton/BackBtn';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';

import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";

const EmployerDetails = () => {
  const { employerId } = useParams();
  const [searchParams] = useSearchParams();
  const u = searchParams.get("u");
  const [employerData, setEmployerData] = useState([]);
  const [employerTransactionsData, setEmployerTransactionsData] = useState([]);
  const [employerPostedJobsData, setEmployerPostedJobsData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const { handleSnackBar } = useContext(GlobalContext);
  const location = useLocation();

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isError, setIsError] = useState(false);

  const [columnFilters2, setColumnFilters2] = useState([]);
  const [globalFilter2, setGlobalFilter2] = useState("");
  const [sorting2, setSorting2] = useState([]);
  const [isRefetching2, setIsRefetching2] = useState(false);
  const [isError2, setIsError2] = useState(false);

  const [rowCount, setRowCount] = useState(0);
  const [rowCount2, setRowCount2] = useState(0);
  const [loadingDataInTranxTable, setLoadingDataInTranxTable] = useState(null);
  const [loadingDataInJobsTable, setLoadingDataInJobsTable] = useState(null);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [pagination2, setPagination2] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const columns = [
    {
      accessorKey: "id",
      header: "S.No.",
      size: 120,
      enableColumnFilter: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        const rowIndex = row.index;
        return <span>{rowIndex + 1 + pageIndex * pageSize}</span>;
      },
    },
    { accessorKey: "merchantTransactionId", size: 250, header: "Transaction ID" },
    { accessorKey: "merchantId", size: 250, header: "Merchant ID" },
    {
      accessorKey: "paymentDate", size: 220, header: "Transaction Date",
      enableColumnFilter: false,
      Cell: ({ cell }) => (cell.getValue() ?
        <span>
          {new Date(cell.getValue()).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })}
        </span> : '--'
      ),
    },
    { accessorKey: "planType", header: "Transaction Type", enableColumnFilter: false, },
    { accessorKey: "paymentMode", size: 180, header: "Mode of Payment", enableColumnFilter: false, },
    { accessorKey: "amount", size: 120, header: "Amount", enableColumnFilter: false, },
    { accessorKey: "state", header: "Payment Status", enableColumnFilter: false, },
  ];


  const jobDataColumns = [
    {
      accessorKey: "id",
      header: "S.No.",
      size: 120,
      enableColumnFilter: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        const rowIndex = row.index;
        return <span>{rowIndex + 1 + pageIndex * pageSize}</span>;
      },
    },
    {
      accessorKey: "jobId",
      header: "Job ID",
      Cell: ({ row }) => (
        <a
          href={`/posted-jobs/${row?.original?._id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline"
          style={{ cursor: canRead ? 'pointer' : 'not-allowed' }}
          onClick={(e) => !canRead && e.preventDefault()}
        >
          {row.original.jobId}
        </a>
      ),
    },
    { accessorKey: "jobRoleNameEn", id: 'jobRole', header: "Job Role" },
    {
      accessorKey: "jobLiveAt",
      enableColumnFilter: false,
      Cell: ({ cell }) => (cell.getValue() ?
        <span>
          {new Date(cell.getValue()).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })}
        </span> : '--'
      ), header: "Date Posted"
    },
    {
      accessorKey: "city",
      header: "Location",
      enableColumnFilter: false,
      accessorFn: (row) => {
        let { city, state } = row.jobDescription?.jobLocation || {};
        return (
          [city, state].filter(Boolean).join(", ")
        )
        // row.jobDescription?.jobLocation.state ?? "---"},
      }
    },
    {
      accessorKey: "status",
      enableColumnFilter: false,
      accessorFn: (row) => {
        if (row?.deleted?.isDeleted) return "Deleted";
        if (row?.isArchived) return "Archived";
        if (row?.isDraft) return "Draft";
        if (row?.isActive) return "Active";
        if (row?.isAdminApprovalNeed) return "Admin Approval Needed";
        if (row?.isRejectedByAdmin) return "Rejected By Admin";
        return "Unknown";
      },
      header: "Status"
    },
  ];

  const getEmployerDetails = async () => {
    try {
      let response = await getRequestWithToken(`admin/userbyid?usertype=employer&userid=${employerId}`);
      setEmployerData(response.data.data);
      // console.log("response : " + JSON.stringify(response.data.data))
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.message || error?.message, "error");
      setShowLoader(false);
    }
  };

  const getEmployerTransactionsDetails = async () => {
    setLoadingDataInTranxTable(true);
    try {

      const filterParams = {
        // global: globalFilter,
        ...Object.fromEntries(
          columnFilters.map((filter) => [filter.id, filter.value])
        ),
      };
      const filterQueryString = new URLSearchParams(filterParams).toString();

      let response = await getRequestWithToken(`admin/employer/transaction?page=${pagination.pageIndex + 1}&limit=${pagination.pageSize}&employerId=${employerId}&${filterQueryString}`);
      setEmployerTransactionsData(response.data.data);
      setRowCount(response?.data?.totalCount);
      // console.log("response : " + JSON.stringify(response.data.data))
      setLoadingDataInTranxTable(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.message || error?.message, "error");
      setLoadingDataInTranxTable(false);
    }
  };

  const getEmployerPostedJobsDetails = async () => {
    setLoadingDataInJobsTable(true);
    try {

      const filterParams = {
        // global: globalFilter,
        ...Object.fromEntries(
          columnFilters2.map((filter) => [filter.id, filter.value])
        ),
      };
      const filterQueryString2 = new URLSearchParams(filterParams).toString();

      let response = await getRequestWithToken(`admin/employer/jobs?page=${pagination2.pageIndex + 1}&limit=${pagination2.pageSize}&employerId=${employerId}&${filterQueryString2}`);
      setEmployerPostedJobsData(response.data.data);
      setRowCount2(response?.data?.totalCount);
      // console.log("response : " + JSON.stringify(response.data.data))
      setLoadingDataInJobsTable(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.message || error?.message, "error");
      setLoadingDataInJobsTable(false);
    }
  };

  useEffect(() => {

    getEmployerDetails();
  }, [employerId
  ]);


  useEffect(() => {
    getEmployerTransactionsDetails();
  }, [employerId,
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  useEffect(() => {
    getEmployerPostedJobsDetails();
  }, [employerId,
    columnFilters2,
    globalFilter2,
    pagination2.pageIndex,
    pagination2.pageSize,
    sorting2,
  ]);



  useEffect(() => {
    // console.log("data response: ", JSON.stringify(employerData));
  }, [employerData]);


  const table = useMaterialReactTable({
    columns,
    data: employerTransactionsData,

    initialState: { showColumnFilters: false },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableGlobalFilter: false,
    // enableColumnFilters: false,
    muiSkeletonProps: {
      animation: "wave",
    },
    muiLinearProgressProps: {
      style: {
        backgroundColor: "#ff671f", // Custom orange color
      },
    },
    muiCircularProgressProps: {
      color: "secondary",
    },
    muiToolbarAlertBannerProps: isError
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading: loadingDataInTranxTable,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderBottomToolbarCustomActions: ({ table }) => {
      const { pageIndex, pageSize } = table.getState().pagination;
      const totalPages = Math.ceil(rowCount / pageSize);
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            Page {pageIndex + 1} of {totalPages}
          </Typography>
        </Box>
      );
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const pinnedColumns = table.getState().columnPinning;
      return (
        <Box>
          <Chip
            label={`Left Pinned: ${pinnedColumns.left.length}`}
            color="primary"
            sx={{ mr: 1 }}
          />
          <Chip
            label={`Right Pinned: ${pinnedColumns.right.length}`}
            color="secondary"
          />
        </Box>
      );
    },
  });


  const jobTable = useMaterialReactTable({
    columns: jobDataColumns,
    data: employerPostedJobsData,

    initialState: { showColumnFilters: false },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableGlobalFilter: false,
    enableColumnFilters: true,
    muiSkeletonProps: {
      animation: "wave",
    },
    muiLinearProgressProps: {
      style: {
        backgroundColor: "#ff671f", // Custom orange color
      },
    },
    muiCircularProgressProps: {
      color: "secondary",
    },
    muiToolbarAlertBannerProps: isError
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters2,
    onGlobalFilterChange: setGlobalFilter2,
    onPaginationChange: setPagination2,
    onSortingChange: setSorting2,
    rowCount: rowCount2,
    state: {
      columnFilters2,
      globalFilter2,
      isLoading: loadingDataInJobsTable,
      pagination: pagination2,
      showAlertBanner: isError2,
      showProgressBars: isRefetching2,
      sorting2,
    },
    renderBottomToolbarCustomActions: ({ table }) => {
      const { pageIndex, pageSize } = table.getState().pagination;
      const totalPages = Math.ceil(rowCount2 / pageSize);
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            Page {pageIndex + 1} of {totalPages}
          </Typography>
        </Box>
      );
    },
    // renderTopToolbarCustomActions: ({ table }) => {
    //   const pinnedColumns = table.getState().columnPinning;
    //   return (
    //     <Box>
    //       <Chip
    //         label={`Left Pinned: ${pinnedColumns.left.length}`}
    //         color="primary"
    //         sx={{ mr: 1 }}
    //       />
    //       <Chip
    //         label={`Right Pinned: ${pinnedColumns.right.length}`}
    //         color="secondary"
    //       />
    //     </Box>
    //   );
    // },
  });

  const checkPermissionFunc = () => {
    const resourceToUpdate = u === "employer" ? "Employer" : "Recruiter";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes(u === "employer" ? "Employer" : "Recruiter"));
  };

  useEffect(() => {
    // getAllJobs();
    checkPermissionFunc();
    againCheckPermissions();
  }, [isIncludesValue]);

  return isIncludesValue ? (
    (showLoader) ? <ReusableLoader /> :
      <Box className="create-candidates-section">

        <Container>

          <Box className="create-candidates">
            <Box className="headingPlusBackBtn">
              {/* <BackBtn /> */}
              <h1 className='formHeadings' style={{ textTransform: 'capitalize' }} id="topEle" >{u} Details</h1>
            </Box>
            <Box className="create-candidates-form-wrapper">

              <Box>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography variant="h7" component="h4">Personal Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className="backgroundStyle">
                      <Box className='marginBottom10'>

                        <Grid container spacing={2} className='cstmFormGridEmployer'>
                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="h7" component='h5'> First Name</Typography>
                              <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{employerData?.firstName ? employerData?.firstName : '--'}</Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="h7" component='h5'> Middle Name</Typography>
                              <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.middleName ? employerData?.middleName : '--'}</Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="h7" component='h5' >Last Name</Typography>
                              <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.lastName ? employerData?.lastName : '--'}</Typography>
                            </Box>
                          </Grid>

                        </Grid>
                      </Box>

                      <Box className='marginBottom10'>

                        <Grid container spacing={2} className='cstmFormGridEmployer'>
                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="h7" component='h5'>Mobile Number</Typography>
                              <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.phone ? employerData?.phone : '--'}</Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="h7" component='h5'>Email Address</Typography>
                              <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{employerData?.email ? employerData?.email : '--'}</Typography>
                            </Box>
                          </Grid>

                        </Grid>
                      </Box>

                      <Box className='marginBottom10'>

                        <Grid container spacing={2} className='cstmFormGridEmployer'>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="h7" component='h5'  >Company Name</Typography>
                              <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{employerData?.companyName ? employerData?.companyName : '--'}</Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="h7" component='h5' > Address</Typography>
                              <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.companyAddress?.fullAddress ? employerData?.companyAddress?.fullAddress : '--'}</Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="h7" component='h5' > Pincode</Typography>
                              <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.companyAddress?.pincode ? employerData?.companyAddress?.pincode : '--'}</Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="h7" component='h5' >City</Typography>
                              <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.companyAddress?.city ? employerData?.companyAddress?.city : '--'}</Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="h7" component='h5' >State</Typography>
                              <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.companyAddress?.state ? employerData?.companyAddress?.state : '--'}</Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="h7" component='h5' >Recruitment Agency</Typography>
                              <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.recruitmentAgency ? "Yes" : 'No'}</Typography>
                            </Box>
                          </Grid>
                        </Grid>

                        {employerData?.businessLogo && <Grid container spacing={2} className='cstmFormGridEmployer' style={{ marginTop: '5px' }}>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <Typography variant="h7" component='h5' >BusinessLogo</Typography>
                              {/* <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.companyAddress?.state}</Typography> */}
                              <img src={employerData?.businessLogo} width={250} style={{ borderRadius: "10px" }} alt="" />
                            </Box>
                          </Grid>

                        </Grid>}
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography variant="h7" component="h4">Transaction Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MaterialReactTable
                      table={table}
                    />
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography variant="h7" component="h4">Posted Jobs Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MaterialReactTable
                      table={jobTable}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>

            </Box>
          </Box>
        </Container>

      </Box >) : <Unauthorized />;
};

export default EmployerDetails;
