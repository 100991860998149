import {
    useMaterialReactTable,
    MaterialReactTable,
  } from "material-react-table";
const TableStatsComponent = ({title ,columns,data}) => {
  const table = useMaterialReactTable({
    columns,
    data: data || [],
    enableKeyboardShortcuts: false,
    enableColumnActions: false,
    enableGlobalFilter: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableStickyHeader: true,
    muiTableContainerProps: { sx: { height: '600px' } }
  });

  return (
    <div>
      <div
        className="title"
        style={{
          margin: "10px 0px",
          fontWeight: "bold",
          fontSize: "14px",
          color: "rgb(160, 160, 160)",
        }}
      >
        {title}
      </div>
        <MaterialReactTable table={table} />
    </div>
  );

}

export default TableStatsComponent;