import "./home.scss";
import Widget from "../../components/widget/Widget";
import React, { useState, useEffect ,useRef } from "react";
import { Box, Grid } from "@mui/material";
import { getRequestWithToken } from "../../utils/ApiRequests";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";

import JobSeekerStats from "./JobSeekerStats";
import EmployerStats from "./EmployerStats";
import PostedJobStats from "./PostedJobStats";

const Home = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  
  const fetchDashboardData = async () => {
    try {
      let response = await getRequestWithToken(`admin/dashboard-stats`);
      setDashboardData(response.data);
      setShowLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, [setDashboardData]);
  return (
    <>
      {showLoader ? (
        <ReusableLoader />
      ) : (
        <Box className="home_section" style={{minHeight:"100vh",maxWidth:"100%"}}>
          <div className="widgets">
            <Grid container spacing={2} className="cstmFormGridEmployer">
              <Grid item xs={4} sm={6} md={2}>
                <Widget
                  type="candidate"
                  amount={dashboardData.data.candidatesCount.count}
                />
              </Grid>
              <Grid item xs={4} sm={6} md={2}>
                <Widget
                  type="employer"
                  amount={dashboardData.data.employersCount.count}
                />
              </Grid>
              <Grid item xs={4} sm={6} md={2}>
                <Widget
                  type="sahayak"
                  amount={dashboardData.data.sahayaksCount.count}
                />
              </Grid>
              <Grid item xs={4} sm={6} md={2}>
                <Widget
                  type="partner"
                  amount={dashboardData.data.partnersCount.count}
                />
              </Grid>
              <Grid item xs={4} sm={6} md={2}>
                <Widget
                  type="activeJob"
                  amount={dashboardData.data.activeJobPostsCount.count}
                />
              </Grid>
            </Grid>
          </div>
           
          <div className="statistics_section" style={{display:"flex",flexDirection:"column",gap:"10px",boxSizing:"border-box",overflow:"hidden"}}>
           <JobSeekerStats />
           <EmployerStats/>
           <PostedJobStats />
          </div>
          

        </Box>
      )}
    </>
  );
};

export default Home;
