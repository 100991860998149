import "./partner.scss";
import { Box, Button } from '@mui/material';
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { useState, useEffect } from "react";
import { DataGrid, gridColumnsTotalWidthSelector } from '@mui/x-data-grid';
import { useParams } from "react-router-dom";
import { getRequestWithToken } from "../../utils/ApiRequests";
import { TimeDisplay } from "../../utils/functions";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";
const ViewCandidate = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [partnerCandidate, setPartnerCandidate] = useState([]);

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const checkPermissionFunc = () => {
      const resourceToUpdate = "Partner";
      const permissions = checkPermission(resourceToUpdate);
      setCanCreate(permissions.canCreate);
      setCanDelete(permissions.canDelete);
      setCanUpdate(permissions.canUpdate);
      setCanRead(permissions.canRead);
    };
  
    const againCheckPermissions = () => {
      const checkAccesInArray = localStorage.getItem("resourceNames");
      setisAccess(checkAccesInArray);
      isIncludes();
    };
  
    const isIncludes = () => {
      setisIncludesValue(isAccess.includes("Partner"));
    };
  
    useEffect(() => {
      checkPermissionFunc();
      againCheckPermissions();
    }, [isIncludesValue]);

  const { id } = useParams();
  const columns = [
    {
      field: 'id', headerName: 'ID', width: 150
    },
    {
      field: 'full_name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'mobile_number',
      headerName: 'Phone number',
      width: 200,
    },
    // {
    //     field: 'date',
    //     headerName: 'Date',
    //     width: 200,
    //     renderCell: (params) => {
    //         return (<>{TimeDisplay((params.row.createdAt))}</>)
    //     },
    // },
    {
      field: 'registered',
      headerName: 'Registered',
      width: 200,
      renderCell: (params) => {
        return (<span style={{ textTransform: "capitalize" }}>{params?.row?.registered}</span>)
      },
    },
    {
      field: 'onboarding_message_delivered',
      headerName: 'Onboarding Message Delivered',
      width: 300,
      renderCell: (params) => {
        return (<span style={{ textTransform: "capitalize" }}>{params?.row?.onboarding_message_delivered}</span>)
      },
    },
    // {
    //     field: 'onboarding_message',
    //     headerName: 'Send Onboarding Message',
    //     width: 250,
    //     renderCell: (params) => {

    //         return (<Button variant="contained">Send Onboarding Message</Button>
    //         )
    //     },
    // },

  ];

  useEffect(() => {
    const getCandidates = async () => {
      try {
        let response = await getRequestWithToken(`/admin/partner-candidate?partner_id=${id}`)
        if (response.status === 201) {
          setPartnerCandidate(response?.data?.data)
          setShowLoader(false);
        }
      } catch (err) {
        console.log(err)
      }
    }
    getCandidates()
  }, [id]);
  const getRowId = (row) => row._id;
  partnerCandidate.length > 0 && partnerCandidate.forEach((row, index) => {
    row.id = index + 1; // This generates IDs starting from 1
  });
  return isIncludesValue ? (<Box className="partner-section">
    <Box className="partner-candidate-data">
      <Box sx={{ height: "100%", width: '100%' }}>
        {showLoader ? <ReusableLoader /> : <DataGrid
          rows={partnerCandidate}
          columns={columns}
          getRowId={getRowId}

        />
        }
      </Box>
      </Box> 
    </Box>) : <Unauthorized/>
}
export default ViewCandidate;