import React from 'react';
import { PieChart, Pie,  Tooltip, ResponsiveContainer } from 'recharts';

const PieChartComponent = ({data,title}) => {
    return (
        <div style={{ backgroundColor: "#fff", boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)", borderRadius: "10px" ,height:"315px"}}>
         <div className="title" style={{ padding: "16px 25px", fontWeight: "bold", fontSize: "14px", color: "rgb(160, 160, 160)" }}>{title}</div>
         <ResponsiveContainer width="100%" height="85%">
          <PieChart width={400} height={280}>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={data}
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill="#8884d8"
              label
            />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
        </div>
      );
}

export default PieChartComponent;
