import React, { useState, useEffect } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { getRequestWithToken } from "../../utils/ApiRequests";
import BarChartComponent from "../../components/barChart/BarChartComponent";
import TableStatsComponent from "../../components/barChart/TableStatsComponent";
const PostedJobStats = () => {
  const [loadingStats,setLoadingStats] = useState(true);
  const [postedJobStats, setPostedJobStats] = useState([]);
  const [locationBasedPostedJobStats, setLocationBasedPostedJobStats] = useState([]);
  const [jobExpertiseBasedPostedJobStats, setJobExpertiseBasedPostedJobStats] = useState([]);

  const columns = [
    {
      accessorKey: "state",
      header: "State",
      accessorFn: (row) => (
        <span style={{ textTransform: "capitalize" }}>{row?.state?.toLowerCase() 
          .split(' ') 
          .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
          .join(' ')}</span>
      ),
    },
    { accessorKey: "totalActiveJobs", header: "Total Active Jobs" },
    { accessorKey: "totalUniqueVacancies", header: "Total Active Vacancies" },
    {
      accessorKey: "totalApplicantHiredCount",
      header: "Total Vacancies Fulfilled",
    },
  ];

  const columns2 = [
    {
      accessorKey: "expertiseName_A",
      header: "Expertise",
      accessorFn: (row) => (
        <span style={{ textTransform: "capitalize" }}>{row?.expertiseName}</span>
      ),
    },
    { accessorKey: "totalActiveJobs", header: "Total Active Jobs" },
    { accessorKey: "totalUniqueVacancyCount", header: "Total Active Vacancies" },
    {
      accessorKey: "totalApplicantHiredCount",
      header: "Total Vacancies Fulfilled",
    },
  ];

  const getPostedJobsStats = async () => {
    try {
      let response = await Promise.allSettled([
        getRequestWithToken(`admin/jobs/job-posts`),
        getRequestWithToken(`admin/jobs/locations`), // Replace with another API endpoint
        getRequestWithToken(`admin/jobs/job-expertise`), // Replace with another API endpoint
        
      ]);
      if (response[0].status === "fulfilled") {
        let jobStatsData = response[0]?.value?.data?.data;
        jobStatsData = jobStatsData[0];
        const transformedData = [
          {
            label: "Total Active Vacancy",
            count: jobStatsData.uniqueVacancyCount || 0,
          },
          {
            label: "Total Active Jobs",
            count: jobStatsData.totalActiveJobs || 0,
          },
          {
            label: "Total Vacancy Fulfilled",
            count: jobStatsData.totalApplicantHiredCount || 0,
          },
        ];
        setPostedJobStats(transformedData);
      }
      if (response[1].status === "fulfilled") {
        let locationBasedJobStats = response[1]?.value?.data?.data;
        setLocationBasedPostedJobStats(locationBasedJobStats);
      }
      if (response[2].status === "fulfilled") {
        let jobExpertiseData = response[2]?.value?.data?.data;
        setJobExpertiseBasedPostedJobStats(jobExpertiseData);
      }
    } catch (error) {
      setPostedJobStats([]);
      setLocationBasedPostedJobStats([]);
    }
    finally{
      setLoadingStats(false);
    }
  };

  useEffect(() => {
    getPostedJobsStats();
  }, []);

  if (loadingStats) {
      return (
        <div
          style={{
            width: "100%",
            height: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "2px",
            }}
          >
            <CircularProgress />
            <p> Loading Statistics ....</p>
          </div>
        </div>
      );
  }

  return (
    <div className="jobPostsCharts" style={{padding:"0px 20px 20px 20px"}}>
      <h3>Posted Job Statistics</h3>
      <Grid container spacing={2} className="cstmFormGridEmployer">
        <Grid item xs={12} sm={6} md={6}>
          <BarChartComponent
            data={postedJobStats}
            title="Posted Job Information"
            aspect={3 / 1}
            barType="vertical"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}></Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TableStatsComponent
            data={locationBasedPostedJobStats}
            columns={columns}
            title="Location Wise"/>
        </Grid>

         <Grid item xs={12} sm={6} md={6}>
          <TableStatsComponent
            data={jobExpertiseBasedPostedJobStats}
            columns={columns2}
            title="Job Expertise Wise"/>
        </Grid>
      </Grid>
    </div>
  );
};

export default PostedJobStats;
