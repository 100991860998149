import React, { useState, useEffect } from "react";
import { Grid, CircularProgress } from "@mui/material";
import { getRequestWithToken } from "../../utils/ApiRequests";
import BarChartComponent from "../../components/barChart/BarChartComponent";
import TableStatsComponent from "../../components/barChart/TableStatsComponent";
const JobSeekerStats = () => {
  const [loadingStats, setLoadingStats] = useState(true);
  const [jobSeekerRegistrationData, setJobSeekerRegistrationData] = useState(
    []
  );
  const [registationCompJS, setRegistrationCompJS] = useState([]);
  const [jobExpertiseUsers, setJobExpertiseUsers] = useState([]);
  const [locationWiseUsers, setLocationWiseUsers] = useState([]);
  
 const columns = [
    {
      accessorKey: "code",
      header: "Job Code",
    },
    { accessorKey: "name", header: "Job Name" },
    { accessorKey: "count", header: "Users" },
    
  ];

  const columns2 = [
    {
      accessorKey: "state",
      header: "State",
      accessorFn: (row) => (
        <span style={{ textTransform: "capitalize" }}>{row.state}</span>
      ),
    },
    { accessorKey: "count", header: "User Count" },
    
  ];

  const getJobseekerStatsData = async () => {
    try {
      let response = await Promise.allSettled([
        getRequestWithToken(`admin/job-seeker/registration`),
        getRequestWithToken(`admin/job-seeker/dashboard-type`), 
        getRequestWithToken(`admin/job-seeker/job-expertise`),
        getRequestWithToken(`admin/job-seeker/states`)

      ]);
      if (response[0].status === "fulfilled") {
        let jobSeekerData = response[0]?.value?.data?.data;
        const jobSeekerRegistrationTransformedData = [
          {
            label: "Total",
            count: jobSeekerData.initiatedRegistrationCount || 0,
          },
          {
            label: "From WA",
            count: jobSeekerData.initiatedRegistrationWhatsappCount || 0,
          },
          {
            label: "From App",
            count: jobSeekerData.initiatedRegistrationAppCount || 0,
          },
          {
            label: "Personal Detail Complete",
            count: jobSeekerData.initiatedRegistrationPersonalDetailCompleteCount || 0,
          },
          {
            label: "Job Type Selected",
            count: jobSeekerData.initiatedRegistrationJobTypeSelectedCount || 0,
          },
          {
            label: "Education & Work Detail Complete",
            count: jobSeekerData.initiatedRegistrationWorkDetailCompleteCount || 0,
          },
        ];
        setJobSeekerRegistrationData(jobSeekerRegistrationTransformedData);
      }
      if (response[1].status === "fulfilled") {
        let jobSeekerData = response[1]?.value?.data?.data[0];
       
        const jobSeekerRegistrationTransformedData = [
          {
            label: "Total",
            count: jobSeekerData.regCompleteWAFlowCompleteCount || 0,
          },
          {
            label: "From WA",
            count: jobSeekerData.regCompleteWASourceCount || 0,
          },
          {
            label: "Job Applied",
            count: jobSeekerData.jobAppliedCount || 0,
          },
          {
            label: "App Users",
            count: jobSeekerData.regCompleteAppDownloadCount || 0,
          },
          {
            label: "eKYC Verified",
            count: jobSeekerData.kycCompletedCount || 0,
          },
          {
            label: "Profile 100%",
            count: jobSeekerData.profileFullCompleted || 0,
          },
        ];
        setRegistrationCompJS(jobSeekerRegistrationTransformedData);
      }
      if(response[2].status === "fulfilled"){
        let locationBasedJobStats = response[2]?.value?.data?.data;
        setJobExpertiseUsers(locationBasedJobStats);
      }
      if(response[3].status === "fulfilled"){
        let locationBasedJobStats = response[3]?.value?.data?.data;
        setLocationWiseUsers(locationBasedJobStats);
      }
    } catch (error) {
      setJobSeekerRegistrationData([]);
      setRegistrationCompJS([]);
      setJobExpertiseUsers([]);
      setLocationWiseUsers([]);
    }
    finally{
      setLoadingStats(false);
    }
  }

  useEffect(() => {
    getJobseekerStatsData();
  }, []);

  if (loadingStats) {
    return (
      <div
        style={{
          width: "100%",
          height: "500px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "2px",
          }}
        >
          <CircularProgress />
          <p> Loading Statistics ....</p>
        </div>
      </div>
    );
  }

  return (
    <div className="jobSeekersCharts" style={{padding:"20px"}}>
      <h3>JobSeeker Statistics</h3>
      <Grid container spacing={2} className="cstmFormGridEmployer">
        <Grid item xs={12} sm={6} md={6}>
          <BarChartComponent
            data={jobSeekerRegistrationData}
            title="Registration Initiated by JobSeekers"
            aspect={3 / 1}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <BarChartComponent
            data={registationCompJS}
            title="Registration Completed by JobSeekers"
            aspect={3 / 1}
            barType="vertical"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
            <TableStatsComponent title="Job Expertise Wise Users" columns={columns} data={jobExpertiseUsers} />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
            <TableStatsComponent title="Location Wise Users" columns={columns2} data={locationWiseUsers} />
        </Grid>
      </Grid>
    </div>
  );
};

export default JobSeekerStats;
